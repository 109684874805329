import React from "react";
import styles from "./OrderFormIsAgreed.scss";
import {tooltip, ValidationWrapper} from "@skbkontur/react-ui-validations";
import {Checkbox, Link} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {BookingOrderContext} from "../../BookingOrder/BookingOrderContext";
import {createValidationInfo} from "../../../forms/ValidationInfo";
import AgreementLink from "../../../components/AgreementLink/AgreementLink";
import {IAppState} from "../../../store/AppState";
import {useSelector} from "react-redux";
import {IBookingOrderState} from "../../../data/BookingOrder";

const OrderFormIsAgreed = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const {offerAgreementLink: offerAgreementUrl} = useSelector((state: IAppState) => state.hotelInfo.info);
    const {
        setForm,
        form: {isAgreed},
    } = React.useContext(BookingOrderContext);

    const getValidation = () => {
        if (!isAgreed) return createValidationInfo(t("bookingAvailableAfterAgreementAboutPersonalData"));
    };

    const handleChange = (isAgreed: boolean) => {
        setForm((value: IBookingOrderState) => ({
            ...value,
            isAgreed,
        }));
    };

    const personalDataLink = (personalDataText: string) => <AgreementLink>{personalDataText}</AgreementLink>;

    const offerAgreementLink = (offerAgreementText: string) => (
        <Link href={offerAgreementUrl} target="_blank" rel="noopener">
            {offerAgreementText}
        </Link>
    );

    return (
        <div className={styles.agreement}>
            <ValidationWrapper validationInfo={getValidation()} renderMessage={tooltip("top left")}>
                <Checkbox checked={isAgreed} onValueChange={handleChange}>
                    {offerAgreementUrl
                        ? t("Agreements.personalDataAndOfferAgreement", {
                              raw: true,
                              reactParams: {
                                  0: personalDataLink,
                                  1: offerAgreementLink,
                              },
                          })
                        : t("Agreements.personalData", {
                              raw: true,
                              reactParams: {
                                  0: personalDataLink,
                              },
                          })}
                </Checkbox>
            </ValidationWrapper>
        </div>
    );
};
OrderFormIsAgreed.displayName = "OrderFormIsAgreed";
export default OrderFormIsAgreed;
