import React from "react";
import {Gapped} from "@skbkontur/react-ui";
import {QRCode} from "react-qrcode-logo";
import styles from "../BookingMakeOrder.scss";
import WidgetStore from "../../../widget/WidgetStore";
import SbpLogo from "../../../images/SbpLogo.svg";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";

interface ITBankSbpDesktopPaymentProps {
    paymentUrl: string;
}

export const TBankSbpDesktopPayment = (props: ITBankSbpDesktopPaymentProps) => {
    const {paymentUrl} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const logo = WidgetStore.getUrlWithBase(SbpLogo);

    return (
        <Gapped gap={40} verticalAlign="top">
            <QRCode
                value={paymentUrl}
                quietZone={0}
                size={171}
                logoImage={logo}
                logoWidth={64}
                logoHeight={64}
                ecLevel="H"
            />
            <ul className={styles.list}>
                <li>{t("tBankActions.openApp")}</li>
                <li>{t("tBankActions.scan")}</li>
                <li>{t("tBankActions.confirm")}</li>
            </ul>
        </Gapped>
    );
};

TBankSbpDesktopPayment.displayName = "TBankSbpDesktopPayment";

