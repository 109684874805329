import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {BookingLightboxDataHelper} from "../../BookingLightboxDataHelper";
import BookingDatesNotAvailableCalendar from "../../DatesNotAvailableCalendar/BookingDatesNotAvailableCalendar";
import {sortBy} from "lodash";
import BookingAccommodationsResults from "./BookingAccommodationsResults";
import {RoomCategorySearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import {Gapped} from "@skbkontur/react-ui";
import BookingExceedCapacityResult from "./BookingExceedCapacityResult";

interface IBookingRoomCategoryResultsProps {
    targetPlacesCount: number;
    isExceedMaxCapacity: boolean;
}

const BookingRoomCategoryResults = (props: IBookingRoomCategoryResultsProps) => {
    const {targetPlacesCount, isExceedMaxCapacity} = props;

    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {roomCategoryId} = params;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {accommodations, accommodationsPricesMap} = React.useContext(AccommodationsContext);

    const matchedAccommodations = React.useMemo(() => (
        BookingLightboxDataHelper.getEqualOrBiggerAccommodations(accommodations, targetPlacesCount, roomCategories)
    ), [accommodations, targetPlacesCount, roomCategories]);

    const targetAccommodations = isExceedMaxCapacity ? accommodations : matchedAccommodations;
    const roomCategoryAccommodations = targetAccommodations.filter(a => a.roomCategoryId === roomCategoryId);
    const othersAccommodations = matchedAccommodations.filter(a => a.roomCategoryId !== roomCategoryId);

    if (!roomCategoryAccommodations.length && !othersAccommodations.length)
        return null;

    const targetOccupancy = isExceedMaxCapacity ? Infinity : targetPlacesCount;
    const getSortParams = BookingLightboxDataHelper.getAccommodationsSortParams(targetOccupancy);
    const sortByPlacesCountASC = BookingLightboxDataHelper.byPlacesCountASC(roomCategories, getSortParams);
    const sortByPlacesCountDESC = BookingLightboxDataHelper.byPlacesCountDESC(roomCategories, getSortParams);
    const sortByMinPrice = BookingLightboxDataHelper.byMinRatePrice(accommodationsPricesMap, getSortParams);

    const sortByPlacesCount = isExceedMaxCapacity ? sortByPlacesCountDESC : sortByPlacesCountASC;
    const sortedRoomCategoryAccommodations = sortBy(roomCategoryAccommodations, sortByPlacesCount, sortByMinPrice);
    const sortedOthersAccommodations = sortBy(othersAccommodations, sortByPlacesCount, sortByMinPrice);

    const sortItemsByPlacesCountASC = BookingLightboxDataHelper.getSortItemsByPlacesCountASC(roomCategories);
    const sortItemsByPlacesCountDESC = BookingLightboxDataHelper.getSortItemsByPlacesCountDESC(roomCategories);
    const sortItemsByPlacesCount = isExceedMaxCapacity ? sortItemsByPlacesCountDESC : sortItemsByPlacesCountASC;

    return (
        <Gapped gap={20} vertical>
            {sortedRoomCategoryAccommodations.length ? (
                <>
                    {isExceedMaxCapacity && <BookingExceedCapacityResult/>}
                    <BookingAccommodationsResults
                        accommodations={sortedRoomCategoryAccommodations}
                        sortItems={sortItemsByPlacesCount}
                    />
                </>
            ) : <BookingDatesNotAvailableCalendar/>}
            {!!sortedOthersAccommodations.length && (
                <BookingAccommodationsResults
                    title={t("accommodations.captions.others")}
                    accommodations={sortedOthersAccommodations}
                    sortItems={sortItemsByPlacesCount}
                />
            )}
        </Gapped>
    );
};
BookingRoomCategoryResults.displayName = "BookingRoomCategoryResults";
export default BookingRoomCategoryResults;
