import React from "react";
import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";
import WidgetStore from "../widget/WidgetStore";
import {isDev} from "@skbkontur/hotel-process-env";
import {initVkBridge} from "../vkBridge";

export const useInitVkBridge = () => {
    const organizationName = useSelector((state: IAppState) => state.hotelInfo.info?.organizationName);

    React.useEffect(() => {
        const {hotelId} = WidgetStore.settings || {};

        if (!organizationName || isDev()) return;

        initVkBridge(organizationName, hotelId);
    }, [organizationName]);
}
