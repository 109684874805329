import React from "react";
import ReactImageLightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {IImage} from "@skbkontur/hotel-data/image";

interface IGalleryModalProps {
    startIndex?: number;
    images: IImage[];
    onClose: () => void;
}

const GalleryLightbox = (props: IGalleryModalProps) => {
    const {startIndex, onClose, images} = props;

    const [imageIndex, setImageIndex] = React.useState(startIndex || 0);

    const prevIndex = (imageIndex + images.length - 1) % images.length;
    const nextIndex = (imageIndex + 1) % images.length;

    return (
        <ReactImageLightbox
            mainSrc={images[imageIndex].url}
            nextSrc={images[nextIndex].url}
            prevSrc={images[prevIndex].url}
            onCloseRequest={onClose}
            onMovePrevRequest={() => setImageIndex(prevIndex)}
            onMoveNextRequest={() => setImageIndex(nextIndex)}
            reactModalStyle={{overlay: {zIndex: 199999}}}
        />
    );
};
GalleryLightbox.displayName = "GalleryLightbox";
export default GalleryLightbox;
