import React from "react";
import cn from "classnames";
import styles from "./HourlyBookingRates.scss";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {WidgetClientGlobalStyles} from "../../../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../../../widget/WidgetStore";
import {MarketShoppingCartIcon} from "@skbkontur/icons/MarketShoppingCartIcon";
import {SumWithLocale} from "../../../../components/SumWithLocale/SumWithLocale";
import {
    HourlyBookingLightboxStep,
    HourlyBookingLightboxStepsContext,
} from "../Steps/HourlyBookingLightboxStepsContext";
import {HourlyBookingLightboxHelper} from "../HourlyBookingLightboxHelper";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";
import {HourlyObjectsSearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import {useSelectedHotelFeatures} from "../Order/useSelectedHotelFeatures";

const HourlyBookingLightboxOrderBook = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const hotelFeatures = useSelectedHotelFeatures();
    const {selectedAccommodation, accommodationPrices} = React.useContext(HourlyAccommodationsContext);
    const {params} = React.useContext(HourlyObjectsSearchParamsContext);

    const {
        featuresCount,
        totalHours,
        totalSum
    } = HourlyBookingLightboxHelper.getTotalInfo({
        selectedAccommodation,
        accommodationPrices,
        hotelFeatures,
        params,
    });

    const {goToStep} = React.useContext(HourlyBookingLightboxStepsContext);

    const buttonWidgetContainerClass = cn({
        [WidgetClientGlobalStyles.WidgetBookingFormButtonContainer]: WidgetStore.isWidget(),
    });

    const handleMakeOrder = () => goToStep(HourlyBookingLightboxStep.FinishOrder);

    return (
        <div className={cn(buttonWidgetContainerClass, styles.paymentBox)}>
            <PrimaryButton data-tid="BookButton" onClick={handleMakeOrder}>
                <div className={styles.paymentButton}>
                    <MarketShoppingCartIcon /> {t("hourlyRates.hoursOrder", {totalHours})} ·{" "}&shy;
                    {featuresCount > 0 && `${t("featuresOrder", {features: featuresCount, stringFormat: true})} · `}&shy;
                    <SumWithLocale sum={totalSum} />
                </div>
            </PrimaryButton>
        </div>
    );
};
HourlyBookingLightboxOrderBook.displayName = "HourlyBookingLightboxOrderBook";
export default HourlyBookingLightboxOrderBook;
