import React from "react";
import {CurrencyInput} from "@skbkontur/react-ui";
import Counter, {ICounterProps} from "./Counter";

const CounterInput = (props: ICounterProps) => (
    <Counter {...props}>
        {(value, onValueChange) => (
            <CurrencyInput
                size="medium"
                width={50}
                signed={false}
                fractionDigits={0}
                value={value}
                onValueChange={onValueChange}
            />
        )}
    </Counter>
);
CounterInput.displayName = "CounterInput";
export default CounterInput;
