import React from "react";
import {IHourlyAccommodationPrice} from "../../../../data/HourlyBooking";
import HourlyBookingAccommodationFlipper from "../Flipper/HourlyBookingAccommodationFlipper";
import HourlyBookingRateItem from "./Item/HourlyBookingRateItem";
import FlippedResizer from "../../../../common/components/Flipper/Resizer/FlippedResizer";
import {sortBy} from "lodash";
import {Currency} from "../../../../common/helpers/Currency";
import {useNotDeletedHourlyFeatures} from "../../../../hooks/useNotDeletedHourlyFeatures";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";
import {HourlyBookingLightboxFlipperHelper} from "../Flipper/HourlyBookingLightboxFlipperHelper";
import BookingRatesHotelFeatures from "../../../../components/HotelFeatures/BookingRatesHotelFeatures";
import styles from "./HourlyBookingRatesAccommodationList.scss";
import {BookingFeaturesHelper} from "../../../../helpers/BookingFeaturesHelper";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";

const HourlyBookingRatesAccommodationList = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const {
        selectedAccommodation,
        accommodationPrices,
        updateAccommodation
    } = React.useContext(HourlyAccommodationsContext);
    const {renderKey, hourlyRateId: rateId, bookingFeatures} = selectedAccommodation;
    const hotelFeatures = useNotDeletedHourlyFeatures();

    const isSelected = !!rateId;
    const pricesToRender = isSelected
        ? [accommodationPrices.find(p => p.rateId === rateId)]
        : sortBy<IHourlyAccommodationPrice>(accommodationPrices, (p: IHourlyAccommodationPrice) => Currency.getFloatValue(p.price));

    const chooseRate = (hourlyRateId: string) => {
        const bookingFeatures = hourlyRateId ? selectedAccommodation.bookingFeatures : {};
        updateAccommodation({...selectedAccommodation, hourlyRateId, bookingFeatures});
    };

    const updateHotelFeature = (hotelFeatureId: string, newCount: number) => {
        const bookingFeatures = BookingFeaturesHelper.filter(
            selectedAccommodation.bookingFeatures,
            hotelFeatureId,
            newCount
        );

        updateAccommodation({
            ...selectedAccommodation,
            bookingFeatures,
        });
    };

    React.useEffect(() => {
        if (!isSelected && pricesToRender.length === 1) {
            const rateId = pricesToRender[0]?.rateId;
            rateId && chooseRate(rateId);
        }
    }, [pricesToRender, isSelected, chooseRate]);

    return (
        <FlippedResizer
            flipId="HourlyRates"
            shouldFlip={HourlyBookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}
        >
            {pricesToRender.map((item: IHourlyAccommodationPrice) => (
                <React.Fragment key={item.rateId}>
                    <HourlyBookingAccommodationFlipper hourlyRateId={item.rateId} renderKey={renderKey}>
                        <HourlyBookingRateItem
                            accommodation={selectedAccommodation}
                            price={item}
                            single={accommodationPrices.length === 1}
                            onSelectRate={chooseRate}
                        />
                    </HourlyBookingAccommodationFlipper>
                    {!!hotelFeatures.length && isSelected && (
                        <HourlyBookingAccommodationFlipper
                            hourlyRateId={`hotelFeatures_${item.rateId}`}
                            renderKey={renderKey}
                        >
                            <div className={styles.featureWrapper}>
                                <BookingRatesHotelFeatures
                                    title={t("hotelFeatures.title")}
                                    rateId={item.rateId}
                                    sellingHotelFeatures={hotelFeatures}
                                    hotelFeaturesMap={bookingFeatures}
                                    updateHotelFeature={updateHotelFeature}
                                />
                            </div>
                        </HourlyBookingAccommodationFlipper>
                    )}
                </React.Fragment>
            ))}
        </FlippedResizer>
    );
};
HourlyBookingRatesAccommodationList.displayName = "HourlyBookingRatesAccommodationList";
export default HourlyBookingRatesAccommodationList;
