import React from "react";
import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";

export const useRoomCategory = (roomCategoryId: string) => {
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    return React.useMemo(() => (
        roomCategories.find(rc => rc.id === roomCategoryId) || {} as IRoomCategory
    ), [roomCategories, roomCategoryId]);
};
