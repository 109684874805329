import React from "react";
import cn from "classnames";
import styles from "./Counter.scss";
import {WidgetClientGlobalStyles} from "../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../widget/WidgetStore";
import {Hint} from "@skbkontur/react-ui";

export interface ICounterProps {
    value: number;
    minValue?: number;
    maxValue?: number;
    onChange: (value: number) => void;
    minValueHint?: React.ReactNode;
    maxValueHint?: React.ReactNode;
}

interface ICounterWithChildrenProps extends ICounterProps {
    children: (value: number, change: (v: number) => void) => React.ReactNode;
}

const Counter = (props: ICounterWithChildrenProps) => {
    let {value} = props;
    const {minValue = 0, maxValue = Infinity, onChange, children, minValueHint, maxValueHint} = props;

    value = value || 0;
    const isDisabledMinusButton = value <= minValue;
    const isDisabledPlusButton = value >= maxValue;

    const plusClasses = cn(styles.button, styles.plus, {
        [styles.disabled]: isDisabledPlusButton,
        [WidgetClientGlobalStyles.WidgetNumberInputButton]: WidgetStore.isWidget()
    });

    const minusClasses = cn(styles.button, styles.minus, {
        [styles.disabled]: isDisabledMinusButton,
        [WidgetClientGlobalStyles.WidgetNumberInputButton]: WidgetStore.isWidget()
    });

    const applyMinMaxValue = (value: number) => (
        Math.min(Math.max(value, minValue), maxValue)
    );

    const onValueChange = (value: number) => {
        onChange(applyMinMaxValue(value));
    };

    const onPlus = () => onValueChange(value + 1);
    const onMinus = () => onValueChange(value - 1);

    const minusButton = <div onClick={onMinus} className={minusClasses}>&minus;</div>;
    const plusButton = <div onClick={onPlus} className={plusClasses}>+</div>;

    const renderButton = (button: React.ReactNode, hint: React.ReactNode, isDisabled: boolean) => {
        if (!isDisabled || !hint)
            return button;

        return (
            <Hint
                text={<div className={styles.hint}>{hint}</div>}
                maxWidth={250}
                useWrapper
            >
                {button}
            </Hint>
        );
    };

    return (
        <div className={styles.container}>
            {renderButton(minusButton, minValueHint, isDisabledMinusButton)}
            {children(applyMinMaxValue(value), onValueChange)}
            {renderButton(plusButton, maxValueHint, isDisabledPlusButton)}
        </div>
    );
};
Counter.displayName = "Counter";
export default Counter;
