// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XCMjRO{color:#222;font-size:20px;margin-bottom:10px}.nu2vt5{color:#222;margin-bottom:50px}", "",{"version":3,"sources":["webpack://./src/providers/Booking/BookingLightbox/DatesNotAvailableCalendar/BookingDatesNotAvailableCalendar.scss","webpack://./src/scss/colors.scss"],"names":[],"mappings":"AAEA,QAGI,UCDa,CDDb,cAAA,CACA,kBCAa,CDIjB,QAEI,UCNa,CDKb,kBCLa","sourcesContent":["@import \"../../../../scss/colors\";\r\n\r\n.emptyTextTitle {\r\n    font-size: 20px;\r\n    margin-bottom: 10px;\r\n    color: $grayscale-text;\r\n}\r\n\r\n.emptyText {\r\n    margin-bottom: 50px;\r\n    color: $grayscale-text;\r\n}\r\n","$grayscale-background: #e6e6e6;\r\n$grayscale-border: #e7e7e7;\r\n$grayscale-hover: #e3e3e3;\r\n$grayscale-light-background: #f0f0f0;\r\n$grayscale-text: #222;\r\n$grayscale-secondary-text: #757575;\r\n$react-ui-focus-color: #1d85d0;\r\n$main-error-text: #cb3d35;\r\n$main-success-background: #d7f8ae;\r\n$grayscale-disabled-text: #adadad;\r\n$blue-hover-color: #1874cf;\r\n$text-color-description: #333;\r\n$border-gray-color: #e7e7e7;\r\n\r\n$white-color: #fff;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyTextTitle": "XCMjRO",
	"emptyText": "nu2vt5"
};
export default ___CSS_LOADER_EXPORT___;
