import React from "react";
import {svgIconsByTypeMap, SvgIconType} from "./SvgIconType";

export interface ISvgIconProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src"> {
    type: SvgIconType;
    modifyImageUrl?: (url: string) => string;
}

const SvgIcon = (props: ISvgIconProps) => {
    const {type, className = "", modifyImageUrl, ...imgProps} = props;

    const imageUrl = modifyImageUrl ? modifyImageUrl(svgIconsByTypeMap[type]) : svgIconsByTypeMap[type];

    return (
        <img
            className={className}
            style={{display: "block"}}
            {...imgProps}
            src={imageUrl}
        />
    );
};
SvgIcon.displayName = "SvgIcon";
export default SvgIcon;
