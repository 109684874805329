import React from "react";
import {GalleryContext} from "./GalleryContext";
import GalleryLightbox from "./GalleryLightbox";
import {Compare} from "../../helpers/CompareHelper";
import {useMemoObject} from "../../common/hooks/useMemoObject";
import {IImage} from "@skbkontur/hotel-data/image";

interface IGalleryState {
    isLightboxOpened: boolean;
    images: IImage[];
    startIndex?: number;
}

const initialState: IGalleryState = {
    isLightboxOpened: false,
    images: null,
    startIndex: 0
};

const GalleryProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const [state, setState] = React.useState<IGalleryState>(initialState);
    const {isLightboxOpened, images, startIndex} = state;

    const openGallery = React.useCallback((images: IImage[], startIndex?: number) => {
        setState({
            isLightboxOpened: true,
            images: [...images].sort(Compare.byOrder),
            startIndex,
        });
    }, []);

    const closeGallery = () => setState(initialState);

    return (
        <GalleryContext.Provider value={useMemoObject({isLightboxOpened, openGallery})}>
            {children}
            {isLightboxOpened && (
                <GalleryLightbox
                    images={images}
                    startIndex={startIndex}
                    onClose={closeGallery}
                />
            )}
        </GalleryContext.Provider>
    );
};
GalleryProvider.displayName = "GalleryProvider";
export default GalleryProvider;
