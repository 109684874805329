import React from "react";
import Fetcher from "../../core/Fetcher";
import {IHourlyAccommodationPrice, IHourlyBookingAccommodation} from "../../data/HourlyBooking";
import {IRoomCategoryAccommodation} from "../../data/Accommodation";
import {ISelectedAccommodationsMap} from "../HourlyAccommodations/HourlyAccommodationsMaps";

type KnownAccommodationsType = IHourlyAccommodationPrice[] | IRoomCategoryAccommodation[];
type KnownSelectedAccommodationType = IHourlyBookingAccommodation | ISelectedAccommodationsMap;


interface IWatchOverbooking {
    isOverbooking: () => Promise<boolean>;
    accommodation: KnownAccommodationsType;
    selectedAccommodation: KnownSelectedAccommodationType;
}

export const useWatchOverbooking = (params: IWatchOverbooking): () => void => {
    const {accommodation, selectedAccommodation, isOverbooking} = params;
    const retryCancelRef = React.useRef<() => void>();

    const stopWatchOverbooking = React.useCallback(() => {
        retryCancelRef.current?.();
    }, []);

    React.useEffect(() => {
        if (accommodation) {
            const retry = Fetcher.retry(isOverbooking, {
                delay: 10 * 1000,
                retries: Infinity,
                needRetryOnSuccess: () => true
            });
            retryCancelRef.current = retry.cancel;
            return () => retry.cancel();
        }
    }, [accommodation, selectedAccommodation]);

    return stopWatchOverbooking;
};
