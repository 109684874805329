import React from "react";
import {Flipper} from "react-flip-toolkit";
import {HourlyBookingLightboxStepsContext} from "../Steps/HourlyBookingLightboxStepsContext";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";

const HourlyBookingLightboxFlipper = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {step} = React.useContext(HourlyBookingLightboxStepsContext);
    const {selectedAccommodation} = React.useContext(HourlyAccommodationsContext);

    return (
        <Flipper flipKey={`${step}`} decisionData={{step, selectedAccommodation}}>
            {children}
        </Flipper>
    );
};
HourlyBookingLightboxFlipper.displayName = "HourlyBookingLightboxFlipper";
export default React.memo(HourlyBookingLightboxFlipper);
