import React from "react";
import {ICurrency} from "../../../../../common/helpers/Currency";
import {useRate} from "../../../../../hooks/useRate";
import {RoomCategorySearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import RateConditions from "../../../../../components/RateConditions/RateConditions";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import dayjs from "dayjs";
import {DateFormats} from "../../../../../types/DateFormats";

interface IBookingRateItemConditionsProps {
    rateId: string;
    cancellationPenaltySum: ICurrency;
    prepaymentSum: ICurrency;
}

const BookingRateItemConditions = (props: IBookingRateItemConditionsProps) => {
    const {rateId, cancellationPenaltySum, prepaymentSum} = props;

    const rate = useRate(rateId);

    const {params: {fromDate}} = React.useContext(RoomCategorySearchParamsContext);
    const {checkInTime} = useSelector((state: IAppState) => state.hotelInfo.info);

    const checkinFormatted = dayjs(fromDate, DateFormats.FullDateDayFirst).format(DateFormats.FullDateDayFirst);
    const checkinWithTime = `${checkinFormatted} ${checkInTime}`;

    return (
        <RateConditions
            cancellationPenaltySum={cancellationPenaltySum}
            prepaymentSum={prepaymentSum}
            rate={rate}
            checkin={checkinWithTime}
        />
    );
};
BookingRateItemConditions.displayName = "BookingRateItemConditions";
export default BookingRateItemConditions;
