import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {PrepaymentType} from "../../data/Rate";
import {ICurrency} from "../../types/Currency";
import {useCurrencySymbol} from "../Currency/useCurrencySymbol";
import {useSumWithLocale} from "../SumWithLocale/useSumWithLocale";
import {Currency} from "../../common/helpers/Currency";

interface IRateConditionsPrepaymentProps {
    prepayment: number;
    prepaymentSum: ICurrency;
    prepaymentType: PrepaymentType;
}

export const RateConditionsPrepayment = (props: IRateConditionsPrepaymentProps) => {
    const {prepayment, prepaymentSum, prepaymentType} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingConfirmation);

    const sumWithLocal = useSumWithLocale(prepaymentSum);
    const currencySymbol = useCurrencySymbol();
    const absoluteCurrencyValue = `${sumWithLocal} ${currencySymbol}`;

    switch (true) {
        case Currency.isZero(prepaymentSum):
            return (
                <div data-tid="withoutPrepayment">
                    {t("withoutPrepayment", {raw: true})}
                </div>
            );
        case prepaymentType === PrepaymentType.PercentOfFirstDayCost:
            return (
                <div data-tid="prepaymentFirstDayCost">
                    {t("prepaymentFirstDayCost", {value: prepayment, measure: "%", absoluteCurrencyValue, raw: true})}
                </div>
            );
        case prepaymentType === PrepaymentType.PercentOfFirstHourCost:
            return (
                <div data-tid="prepaymentFirstHourlyCost">
                    {t("prepaymentFirstHourlyCost", {
                        value: prepayment,
                        measure: "%",
                        absoluteCurrencyValue,
                        raw: true,
                    })}
                </div>
            );
        case prepaymentType === PrepaymentType.PercentOfReservationCost:
            return (
                <div data-tid="prepaymentReservationCost">
                    {t("prepaymentReservationCost", {
                        value: prepayment,
                        measure: "%",
                        absoluteCurrencyValue,
                        raw: true
                    })}
                </div>
            );
        default:
            return (
                <div data-tid="prepaymentAbsolute">
                    {t("prepaymentAbsolute", {
                        value: absoluteCurrencyValue,
                        raw: true
                    })}
                </div>
            );
    }
};

RateConditionsPrepayment.displayName = "RateConditionsPrepayment";
