import React from "react";
import {ILocalStoragePaymentModalResults, ILocalStoragePaymentModalResultsItem, LocalStorageKeys} from "../../constants/LocalStorageKeys";
import {PaymentResult} from "../../data/Payment";
import LocalStorage from "@skbkontur/hotel-storage/localStorage/LocalStorage";

interface IUseLocalStoragePaymentResults {
    paymentResults: ILocalStoragePaymentModalResultsItem;
    setResult: (result: PaymentResult) => void;
}

export const useLocalStoragePaymentResults = (paymentId: string): IUseLocalStoragePaymentResults => {
    const results = LocalStorage.get<ILocalStoragePaymentModalResults>(LocalStorageKeys.PaymentModalResults) || {};
    const {[paymentId]: paymentResults = {} as ILocalStoragePaymentModalResultsItem} = results;

    const setResult = React.useCallback((result: PaymentResult) => {
        if (paymentId) {
            const results = LocalStorage.get<ILocalStoragePaymentModalResults>(LocalStorageKeys.PaymentModalResults) || {};
            LocalStorage.update(LocalStorageKeys.PaymentModalResults, {
                [paymentId]: {
                    ...(results[paymentId] || {}),
                    [result]: true
                }
            });
        }
    }, [paymentId]);

    return {paymentResults, setResult};
};
