import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import styles from "./BookingRatesHotelFeatures.scss";

const BookingRatesHotelFeatureIncludedInRate = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    return (
        <div className={styles.rightBlock}>
            <span className={styles.includedInRate}>
                {t("hotelFeatures.button.includedInRate")}
            </span>
        </div>
    );
};

BookingRatesHotelFeatureIncludedInRate.displayName = "BookingRatesHotelFeatureIncludedInRate";
export default BookingRatesHotelFeatureIncludedInRate;
