import React from "react";
import ItemWithBorder from "../ItemWithBorder/ItemWithBorder";
import FlippedElement from "../../common/components/Flipper/Element/FlippedElement";
import {FlipperHelper} from "../../common/components/Flipper/FlipperHelper";
import styles from "./RoomCategoriesList.scss";

interface IRoomCategoriesListFlipperProps {
    roomCategoryId: string;
    isLast: boolean;
}

const RoomCategoriesListFlipper = (props: React.PropsWithChildren<IRoomCategoriesListFlipperProps>) => {
    const {roomCategoryId, isLast, children} = props;

    return (
        <FlippedElement
            flipId={roomCategoryId}
            onAppear={FlipperHelper.onAppear(styles.fadeIn, 500)}
            withDelay
            withDiv
            withContext
        >
            <ItemWithBorder withoutBorder={isLast}>
                {children}
            </ItemWithBorder>
        </FlippedElement>
    );
};
RoomCategoriesListFlipper.displayName = "RoomCategoriesListFlipper";
export default React.memo(RoomCategoriesListFlipper);
