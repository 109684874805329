import {Gapped} from "@skbkontur/react-ui";
import React from "react";
import {IHourlyObjectsItemAvailableTime} from "./HourlyObjectsListAvailableTime";
import styles from "./HourlyObjectsList.scss";
import cn from "classnames";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {Currency} from "../../common/helpers/Currency";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";

interface IHourlyObjectsTimeItemProps extends IHourlyObjectsItemAvailableTime {
    withoutPlaceholderPrice?: boolean;
    isNotAvailable?: boolean;
}

const HourlyObjectsTimeItem = (props: IHourlyObjectsTimeItemProps) => {
    const {timestamp, price, withoutPlaceholderPrice, isNotAvailable} = props;
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const className = cn(styles.timeItem, {[styles.timeItemDisable]: isNotAvailable});

    return (
        <Gapped gap={0} vertical className={className}>
            <div>
                {DateHelper.convertWithTimezone(timestamp, {
                    formatIn: DateFormats.UnixMsTimestamp,
                    formatOut: DateFormats.OnlyTime,
                    offsetInMinutes,
                })}
            </div>
            {(!withoutPlaceholderPrice || isNotAvailable) && (
                <div className={cn(styles.timeItemPlaceholder, {
                    [styles.timeItemPrice]: !isNotAvailable
                })}>
                    {isNotAvailable
                        ? t("notAvailable")
                        : t("price", {price: Currency.formatDisplayValue(price, true)})}
                </div>
            )}
        </Gapped>
    );
};

export default HourlyObjectsTimeItem;
