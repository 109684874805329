import React from "react";
import {BedType, IBedStructure, IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import styles from "../RoomCategoryCharacteristics.scss";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";
import {People3Icon16Light} from "@skbkontur/icons/People3Icon16Light";
import {SvgIcon, SvgIconType} from "@skbkontur/hotel-components/svgIcon";
import {withRenderKey} from "@skbkontur/hotel-data/renderKey";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";

interface IRoomCategoryCharacteristicsIconsProps {
    category: IRoomCategory;
    hideArea: boolean;
    beds: IBedStructure[];
    modifyImageUrl?: (url: string) => string;
}

export const BED_ICONS: Record<BedType, SvgIconType> = {
    [BedType.Single]: SvgIconType.SingleBed,
    [BedType.Double]: SvgIconType.DoubleBed,
    [BedType.DoubleKing]: SvgIconType.DoubleBedKingSize,
    [BedType.DoubleSplit]: SvgIconType.DoubleSplitBed,
    [BedType.Bunk]: SvgIconType.BunkBed,
};

const RoomCategoryCharacteristicsIcons = (props: IRoomCategoryCharacteristicsIconsProps) => {
    const {
        category: {area , placesMin, placesMax, roomCategoryType, rooms},
        hideArea,
        beds,
        modifyImageUrl
    } = props;
    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const isHostel = RoomCategoryHelper.isHostel(roomCategoryType);
    const hostelPlaces = placesMax === placesMin ? placesMin : `${placesMin}−${placesMax}`;
    const isHasNotHiddenArea = !!area && !hideArea;
    const isHasPlaces = !!placesMin;

    if (!(isHasNotHiddenArea || isHasPlaces || rooms?.length)) {
        return null;
    }

    const bedsWithKeys = beds?.map(withRenderKey);

    return (
        <div className={styles.icons}>
            {isHasNotHiddenArea && (
                <div>
                    <span data-tid="Area">{area}</span>&nbsp;
                    <span>{t("squareMetersCaption")}</span>
                </div>
            )}
            {!isHostel && isHasPlaces && (
                <div className={styles.iconBlock}>
                    <span data-tid="PlacesCount">{placesMin}</span>
                    <People1Icon16Light />
                </div>
            )}
            {isHostel && !!hostelPlaces && (
                <div className={styles.iconBlock}>
                    <span data-tid="HostelPlacesCount">{hostelPlaces}</span>
                    <People3Icon16Light />
                </div>
            )}
            {!!bedsWithKeys?.length && (
                <div className={styles.iconBlock}>
                    {bedsWithKeys.map(bed => (
                        <SvgIcon key={bed.renderKey} type={BED_ICONS[bed.type]} modifyImageUrl={modifyImageUrl} />
                    ))}
                </div>
            )}
            {isHostel && !hideArea && <div>{t("hostelRoom")}</div>}
        </div>
    );
};
RoomCategoryCharacteristicsIcons.displayName = "RoomCategoryCharacteristicsIcons";
export default RoomCategoryCharacteristicsIcons;