import React from "react";
import {IAccommodation} from "../../../../../data/Accommodation";
import {AccommodationRestrictionsHelper} from "../../../../Accommodations/helpers/AccommodationRestrictionsHelper";
import {LocalizedDateFormat, Localizer, useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import styles from "./BookingRoomCategoryRestrictions.scss";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";

interface IBookingRoomCategoryRestrictionsProps {
    accommodations: IAccommodation[];
}

const BookingRoomCategoryRestrictions = (props: IBookingRoomCategoryRestrictionsProps) => {
    const {accommodations} = props;

    const minSeverityRestrictions = React.useMemo(() => (
        AccommodationRestrictionsHelper.getMinSeverityRestrictions(accommodations)
    ), [accommodations]);

    const {arrivalDate, departureDate, minStay, maxStay} = minSeverityRestrictions;
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const ArrivalDate = !!arrivalDate && (
        <span className={styles.noWrap} data-tid="ArrivalDate">
            {Localizer.dates.format(arrivalDate, LocalizedDateFormat.NumericDateWithTextMonth)}
        </span>
    );

    const DepartureDate = !!departureDate && (
        <span className={styles.noWrap} data-tid="DepartureDate">
            {Localizer.dates.format(departureDate, LocalizedDateFormat.NumericDateWithTextMonth)}
        </span>
    );

    const MinStay = !!minStay && (
        <span className={styles.noWrap} data-tid="MinStay">
            {t("recommendations.minStay", {daysCount: minStay})}
        </span>
    );

    const MaxStay = !!maxStay && (
        <span className={styles.noWrap} data-tid="MaxStay">
            {t("recommendations.maxStay", {daysCount: maxStay})}
        </span>
    );

    const StayDays = MinStay || MaxStay;

    switch (true) {
        case Boolean(ArrivalDate && DepartureDate):
            return (
                <div className={styles.restriction} data-tid="ArrivalAndDeparture">
                    {t("recommendations.arrivalAndDeparture", {reactParams: {ArrivalDate, DepartureDate}, raw: true})}
                </div>
            );
        case Boolean(ArrivalDate && StayDays):
            return (
                <div className={styles.restriction} data-tid="ArrivalAndStay">
                    {t("recommendations.arrivalAndStay", {reactParams: {ArrivalDate, StayDays}, raw: true})}
                </div>
            );
        case Boolean(DepartureDate && StayDays):
            return (
                <div className={styles.restriction} data-tid="DepartureAndStay">
                    {t("recommendations.departureAndStay", {reactParams: {DepartureDate, StayDays}, raw: true})}
                </div>
            );
        case Boolean(StayDays):
            return (
                <div className={styles.restriction} data-tid="Stay">
                    {t("recommendations.stay", {reactParams: {StayDays}})}
                </div>
            );
        case Boolean(ArrivalDate):
            return (
                <div className={styles.restriction} data-tid="Arrival">
                    {t("recommendations.arrival", {reactParams: {ArrivalDate}, raw: true})}
                </div>
            );
        case Boolean(DepartureDate):
            return (
                <div className={styles.restriction} data-tid="Departure">
                    {t("recommendations.departure", {reactParams: {DepartureDate}, raw: true})}
                </div>
            );
        default:
            captureSentryError(
                `Unexpected recommendations case, min severity recommendations: ${JSON.stringify(minSeverityRestrictions)}`,
                SentryErrorType.SwitchCase
            );
            return null;
    }
};
BookingRoomCategoryRestrictions.displayName = "BookingRoomCategoryRestrictions";
export default BookingRoomCategoryRestrictions;
