import React from "react";
import {CSSTransition} from "react-transition-group";
import {ExitHandler} from "react-transition-group/Transition";

export interface IRollUpProps {
    isShown: boolean;
    timeout?: number;
    paddingTop?: number;
    paddingBottom?: number;
}

// copied from hotel
export default class RollUp extends React.Component<IRollUpProps> {
    visibleHeight: number;
    DEFAULT_TIMEOUT = 300;

    handleEnter = (content: HTMLElement) => {
        content.style.transition = "none";
        content.style.overflow = "auto";
        content.style.maxHeight = "auto";
        this.visibleHeight = content.clientHeight || this.visibleHeight;
        content.style.overflow = "hidden";
        content.style.maxHeight = "0";
        content.style.opacity = "0.01";
    };

    handleEntering = (content: HTMLElement) => {
        content.style.transition = this.getTimeout() + "ms";
        content.style.maxHeight = this.visibleHeight + "px";
        content.style.opacity = "1";
    };

    handleEntered = (content: HTMLElement) => {
        content.style.transition = "none";
        content.style.overflow = "visible";
        content.style.maxHeight = "none";
    };

    handleExit = (content: HTMLElement) => {
        content.style.transition = "none";
        content.style.maxHeight = content.clientHeight + "px";
        content.style.overflow = "hidden";
        content.style.opacity = "1";
    };

    handleExiting = (content: HTMLElement) => {
        content.style.transition = this.getTimeout() + "ms";
        content.style.maxHeight = "0";
        content.style.opacity = "0.01";
    };

    render() {
        const {isShown, children, paddingTop = 0, paddingBottom = 0} = this.props;
        return (
            <CSSTransition
                classNames={"rollUp"}
                in={isShown}
                timeout={this.getTimeout()}
                unmountOnExit
                onEnter={this.handleEnter as ExitHandler<HTMLElement>}
                onEntering={this.handleEntering as ExitHandler<HTMLElement>}
                onEntered={this.handleEntered as ExitHandler<HTMLElement>}
                onExit={this.handleExit as ExitHandler<HTMLElement>}
                onExiting={this.handleExiting as ExitHandler<HTMLElement>}
            >
                <div>
                    <div style={{paddingTop, paddingBottom}}>{children}</div>
                </div>
            </CSSTransition>
        );
    }

    getTimeout = () => this.props.timeout || this.DEFAULT_TIMEOUT;
}
