import React from "react";
import styles from "./GuestsSelectControl.scss";
import {Input, Tooltip} from "@skbkontur/react-ui";
import {useSelector} from "react-redux";
import {ValidationInfo, ValidationWrapper} from "@skbkontur/react-ui-validations";
import {createValidationInfo} from "../../forms/ValidationInfo";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IAppState} from "../../store/AppState";
import GuestsSelectTooltip from "./GuestsSelectTooltip";
import {ArrowCDownIcon16Regular} from "@skbkontur/icons/ArrowCDownIcon16Regular";

interface IGuestsSelectControlProps {
    adults: IGuestSelectField;
    kids: IGuestSelectField;
    alwaysShowKids?: boolean;
    onApply?: () => void;
    onValidate?: (validationInfo: ValidationInfo) => void;
}

export interface IGuestSelectField {
    count: number;
    onChange: (value: number) => void;
}

const GuestsSelectControl = (props: IGuestsSelectControlProps) => {
    const {adults, kids, alwaysShowKids, onValidate, onApply} = props;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);
    const {freeChildrenOn} = useSelector((state: IAppState) => state.hotelInfo.info);
    const [opened, setOpened] = React.useState(false);
    const tooltipRef = React.useRef<HTMLDivElement>();

    const validate = (): ValidationInfo => {
        const isEmptyAdults = !adults.count;
        const isEmptyKids = !freeChildrenOn || !kids.count;

        const result = isEmptyAdults && isEmptyKids
            ? createValidationInfo(t("validations.guestsCountPositive"))
            : null;

        onValidate?.(result);
        return result;
    };

    const controlText = (freeChildrenOn || alwaysShowKids) && kids.count
        ? tcn("guestsWithChildren", {adultsCount: adults.count, childrenCount: kids.count, stringFormat: true})
        : tcn("adultsCount", {count: adults.count, stringFormat: true});

    const handleApply = () => {
        setOpened(false);
        onApply();
    };

    return (
        <Tooltip
            render={() => (
                <GuestsSelectTooltip
                    adults={adults}
                    kids={kids}
                    alwaysShowKids={alwaysShowKids}
                    onApply={handleApply}
                />
            )}
            pos="bottom left"
            closeButton
            trigger={opened ? "opened" : "closed"}
            onCloseRequest={() => setOpened(false)}
        >
            <div ref={tooltipRef} className={styles.control} onClick={() => setOpened(true)}>
                <ValidationWrapper validationInfo={validate()}>
                    <Input
                        rightIcon={<ArrowCDownIcon16Regular size={14} color="#D7D7D7" />}
                        width="100%"
                        size="medium"
                        value={controlText}
                        readOnly
                    />
                </ValidationWrapper>
            </div>
        </Tooltip>
    );
};
GuestsSelectControl.displayName = "GuestsSelectControl";
export default GuestsSelectControl;
