import React from "react";
import FlippedElement from "../../../../../common/components/Flipper/Element/FlippedElement";
import styles from "./BookingRateItem.scss";
import {BookingLightboxFlipperHelper} from "../../Flipper/BookingLightboxFlipperHelper";

interface IBookingRateItemNameProps {
    renderKey: string;
    name: string;
}

const BookingRateItemName = (props: IBookingRateItemNameProps) => {
    const {name, renderKey} = props;

    return (
        <FlippedElement
            flipId="Name"
            shouldFlip={BookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}
            withInverseParent
            inline
        >
            <span className={styles.name}>{name}</span>
        </FlippedElement>
    );
};
BookingRateItemName.displayName = "BookingRateItemName";
export default BookingRateItemName;
