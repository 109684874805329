import React from "react";
import {IRoomCategoryAccommodation} from "../../../../../data/Accommodation";
import BookingLightboxFlipperRoomCategory from "../../Flipper/BookingLightboxFlipperRoomCategory";
import RoomCategoryCard from "../../../../../components/RoomCategoryCard/RoomCategoryCard";
import BookingAccommodationsList from "../List/BookingAccommodationsList";
import {getAccommodationId} from "../Results/BookingAccommodationsResults";
import BookingRoomCategoryRestrictions from "./BookingRoomCategoryRestrictions";
import {AccommodationRestrictionsHelper} from "../../../../Accommodations/helpers/AccommodationRestrictionsHelper";
import {sortBy} from "lodash";
import {AccommodationItemsSortType} from "../../BookingLightboxDataHelper";

interface IBookingRoomCategoryAccommodationProps {
    accommodation: IRoomCategoryAccommodation;
    isLast: boolean;
    sortItems?: AccommodationItemsSortType;
}

const BookingRoomCategoryAccommodation = (props: IBookingRoomCategoryAccommodationProps) => {
    const {accommodation, isLast, sortItems} = props;
    const {roomCategoryId, accommodations} = accommodation;

    const isRestrictedRoomCategory = AccommodationRestrictionsHelper.isRestrictedRoomCategory(accommodation);
    const accommodationWithSortedItems = {
        ...accommodation,
        accommodations: sortItems
            ? sortBy(accommodations, sortItems(roomCategoryId))
            : accommodations
    };

    return (
        <div id={getAccommodationId(roomCategoryId)}>
            <BookingLightboxFlipperRoomCategory
                roomCategoryId={roomCategoryId}
                isLast={isLast}
            >
                <RoomCategoryCard autoPlaySlider id={roomCategoryId}>
                    {isRestrictedRoomCategory
                        ? <BookingRoomCategoryRestrictions accommodations={accommodations}/>
                        : <BookingAccommodationsList accommodation={accommodationWithSortedItems}/>}
                </RoomCategoryCard>
            </BookingLightboxFlipperRoomCategory>
        </div>
    );
};
BookingRoomCategoryAccommodation.displayName = "BookingRoomCategoryAccommodation";
export default BookingRoomCategoryAccommodation;
