import React from "react";
import SliderDot from "./SliderDot";
import styles from "../Slider.scss";
import {useSliderDotIndexList} from "./useSliderDotIndexList";
import {ISliderImage} from "../types/SliderImage";

interface ISliderDotsProps {
    slideNumber: number;
    images: ISliderImage[];
    goToSlide: (value: number) => void;
}

const SliderDotsList = (props: ISliderDotsProps) => {
    const {images, slideNumber, goToSlide} = props;

    const dotIndexList = useSliderDotIndexList({images, slideNumber});

    return (
        <div className={styles.dots}>
            {dotIndexList.map(index => (
                <SliderDot
                    key={index}
                    isSelected={slideNumber === index}
                    slideNumber={index}
                    goToSlide={goToSlide}
                />
            ))}
        </div>
    );
};

SliderDotsList.displayName = "SliderDotsList";
export default SliderDotsList;
