import React from "react";
import BookingOrderBook from "./Payment/BookingOrderBook";
import {BookingPayment} from "./Payment/BookingPayment";
import TBankSbpPayment from "./TBankSbp/TBankSbpPayment";

interface IBookingMakeOrderProps {
    hasPrepayment: boolean | undefined;
    isTBankPaymentProcess?: boolean;
    isCashback?: boolean;
    isLoading?: boolean;
    paymentUrl: string;
    isOverbookingLoading?: boolean;
}

const BookingMakeOrder = (props: IBookingMakeOrderProps) => {
    const {
        hasPrepayment,
        isTBankPaymentProcess,
        isCashback,
        isLoading,
        paymentUrl,
        isOverbookingLoading
    } = props;

    if (!hasPrepayment) {
        return <BookingOrderBook isLoading={isLoading || isOverbookingLoading} />;
    }

    return isTBankPaymentProcess ? (
        <TBankSbpPayment paymentUrl={paymentUrl} />
    ) : (
        <BookingPayment isCashback={isCashback} isOverbookingLoading={isOverbookingLoading} />
    );
};
BookingMakeOrder.displayName = "BookingMakeOrder";
export default BookingMakeOrder;
