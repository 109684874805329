import ErrorBoundaryLightbox from "./ErrorBoundaryLightbox";
import {SentryErrorType, SentryTag} from "@skbkontur/hotel-sentry";
import React from "react";
import * as Sentry from "@sentry/react";
import {getSentryMOBType, SentryMOBTag} from "../../data/Sentry";

export const WithErrorBoundaryLightbox = <TProps extends object>(
    Component: React.ComponentType<TProps>
) => {
    const WrappedComponent = (props?: TProps) => {

        const [isLightboxOpened, setIsLightboxOpened] = React.useState<boolean>();

        const openErrorBoundaryLightbox = React.useCallback(() => {
            setIsLightboxOpened(true);
        }, []);

        const closeErrorBoundaryLightbox = React.useCallback(() => {
            setIsLightboxOpened(false);
        }, []);

        return (
            <>
                <Sentry.ErrorBoundary
                    beforeCapture={scope => {
                        scope.setTag(SentryMOBTag.MOBType, getSentryMOBType());
                        scope.setTag(SentryTag.Type, SentryErrorType.ErrorBoundary);
                    }}
                    onError={openErrorBoundaryLightbox}
                >
                    <Component {...props} />
                </Sentry.ErrorBoundary>
                {isLightboxOpened && <ErrorBoundaryLightbox onClose={closeErrorBoundaryLightbox} />}
            </>
        );
    };
    WrappedComponent.displayName = "WithErrorBoundary";
    return WrappedComponent;
};
