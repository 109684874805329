import React from "react";
import {IBookingOrderState} from "../../data/BookingOrder";
import {PaymentSystem} from "../../data/Payment";

export interface IBookingOrderContext {
    setForm: React.Dispatch<React.SetStateAction<IBookingOrderState>>;
    form: IBookingOrderState;
    book: (paymentSystem?: PaymentSystem) => void;
}

export const BookingOrderContext = React.createContext({} as IBookingOrderContext);
