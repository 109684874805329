import React from "react"
import FixedButton from "../../../components/FixedButton/FixedButton"
import {DeviceContext} from "../../../providers/Device/DeviceContext";

const WidgetAvailabilityButtonForMobileDevices = () => {
    const {isMobileMode} = React.useContext(DeviceContext);
    if (!isMobileMode) {
        return null;
    }

    return <FixedButton />
}

export default WidgetAvailabilityButtonForMobileDevices
