import React from "react";
import cn from "classnames";
import styles from "../Slider.scss";

interface ISliderDotProps {
    slideNumber: number;
    isSelected: boolean;
    goToSlide: (value: number) => void;
}

const SliderDot = (props: ISliderDotProps) => {
    const {slideNumber, isSelected, goToSlide} = props;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        goToSlide(slideNumber);
    };

    return (
        <div
            className={cn(styles.dot, {[styles.selected]: isSelected})}
            onClick={handleClick}
        />
    );
};

SliderDot.displayName = "SliderDot";
export default SliderDot;
