import React from "react";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import styles from "./BookingAccommodationsPanel.scss";

interface IBookingAccommodationsPanelWarningProps {
    adultsCount: number;
    childrenCount: number;
}

const BookingAccommodationsPanelWarning = (props: IBookingAccommodationsPanelWarningProps) => {
    const {adultsCount, childrenCount} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    let warning;
    const countInfo = (countText: string) => (
        <span className={styles.count}>{countText}</span>
    );

    switch (true) {
        case adultsCount > 0 && childrenCount > 0:
            warning = t("validations.selectMoreForAdultsAndChildren", {adultsCount, childrenCount, reactParams: {0: countInfo}});
            break;
        case adultsCount > 0:
            warning = t("validations.selectMoreForAdults", {adultsCount, reactParams: {0: countInfo}});
            break;
        case childrenCount > 0:
            warning = t("validations.selectMoreForChildren", {childrenCount, reactParams: {0: countInfo}});
    }

    return warning && (
        <div className={styles.warning}>
            {warning}
        </div>
    );
};
BookingAccommodationsPanelWarning.displayName = "BookingAccommodationsPanelWarning";
export default BookingAccommodationsPanelWarning;
