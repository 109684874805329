import React from "react";
import styles from "./RequisitesLightbox.scss";
import {useTranslation} from "@skbkontur/i18n";
import {Modal} from "@skbkontur/react-ui";
import {ZIndex} from "@skbkontur/react-ui/internal/ZIndex";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IRussianRequisites} from "../../data/Requisites";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

interface IRussianRequisitesLightboxProps {
    requisites: IRussianRequisites;
    onClose: () => void;
}

export const RussianRequisitesLightbox = (props: IRussianRequisitesLightboxProps) => {
    const {requisites, onClose} = props;
    const {inn, kpp, ogrn, bic, correspondentAccountNumber, lawAddress, bankName, bankAccountNumber, legalName} = requisites;
    const hasAnyBankRequisite = bankName || bic || bankAccountNumber || correspondentAccountNumber;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);

    return (
        <ZIndex delta={999999}>
            <Modal
                disableFocusLock
                width={560}
                onClose={onClose}
                ignoreBackgroundClick
            >
                <Modal.Header>
                    {t("requisites.name")}
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.headerHotelInfo}>{legalName}</div>
                    <div>
                        {inn && <div>{t("requisites.inn")}: {inn}</div>}
                        {kpp && <div>{t("requisites.kpp")}: {kpp}</div>}
                        {ogrn && <div>{t("requisites.ogrn")}: {ogrn}</div>}
                        {lawAddress && <div>{t("requisites.lawAddress")}: {lawAddress}</div>}
                        {hasAnyBankRequisite && (
                            <div>
                                <div className={styles.headerBankAccount}>{t("bankAccount.name")}</div>
                                {bankName && <div>{t("bankAccount.bankName")}: {bankName}</div>}
                                {bic && <div>{t("bankAccount.bic")}: {bic}</div>}
                                {bankAccountNumber && <div>{t("bankAccount.bankAccountNumber")}: {bankAccountNumber}</div>}
                                {correspondentAccountNumber && (
                                    <div>
                                        {t("bankAccount.correspondentAccountNumber")}: {correspondentAccountNumber}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton use="default" onClick={onClose}>
                        {tcn("buttons.close")}
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>
        </ZIndex>
    );
};
