import Checkin from "./icons/Checkin.svg";
import Checkout from "./icons/Checkout.svg";
import Wallet from "./icons/Wallet.svg";
import WalletBlue from "./icons/WalletBlue.svg";
import QrCode from "./icons/QrCode.svg";
import QrCodeBlue from "./icons/QrCodeBlue.svg";
import GuestBlacklist from "./icons/GuestBlacklist.svg";
import GuestBlacklistProblem from "./icons/GuestBlacklistProblem.svg";
import GuestBlacklistOk from "./icons/GuestBlacklistOk.svg";
import Night from "./icons/Night.svg";
import DeleteCross from "./icons/DeleteCross.svg";
import Watches from "./icons/Watches.svg";
import Kitchen from "./icons/Kitchen.svg";
import Shower from "./icons/Shower.svg";
import SingleBed from "./icons/SingleBed.svg";
import DoubleBed from "./icons/DoubleBed.svg";
import DoubleBedKingSize from "./icons/DoubleBedKingSize.svg";
import DoubleSplitBed from "./icons/DoubleSplitBed.svg";
import BunkBed from "./icons/BunkBed.svg";
import Delete from "./icons/Delete.svg";

export enum SvgIconType {
    Checkin = "Checkin",
    Checkout = "Checkout",
    Wallet = "Wallet",
    WalletBlue = "WalletBlue",
    QrCode = "QrCode",
    QrCodeBlue = "QrCodeBlue",
    GuestBlacklist = "GuestBlacklist",
    GuestBlacklistProblem = "GuestBlacklistProblem",
    GuestBlacklistOk = "GuestBlacklistOk",
    DeleteCross = "DeleteCross",
    Watches = "Watches",
    Kitchen = "Kitchen",
    Shower = "Shower",
    SingleBed = "SingleBed",
    DoubleBed = "DoubleBed",
    DoubleBedKingSize = "DoubleBedKingSize",
    DoubleSplitBed = "DoubleSplitBed",
    BunkBed = "BunkBed",
    Delete = "Delete",

    /** Supports fill="currentColor" */
    Night = "Night",
}

export const svgIconsByTypeMap: Record<SvgIconType, string> = {
    [SvgIconType.Checkin]: Checkin,
    [SvgIconType.Checkout]: Checkout,
    [SvgIconType.Wallet]: Wallet,
    [SvgIconType.WalletBlue]: WalletBlue,
    [SvgIconType.QrCode]: QrCode,
    [SvgIconType.QrCodeBlue]: QrCodeBlue,
    [SvgIconType.GuestBlacklist]: GuestBlacklist,
    [SvgIconType.GuestBlacklistProblem]: GuestBlacklistProblem,
    [SvgIconType.GuestBlacklistOk]: GuestBlacklistOk,
    [SvgIconType.Night]: Night,
    [SvgIconType.DeleteCross]: DeleteCross,
    [SvgIconType.Watches]: Watches,
    [SvgIconType.Kitchen]: Kitchen,
    [SvgIconType.Shower]: Shower,
    [SvgIconType.SingleBed]: SingleBed,
    [SvgIconType.DoubleBed]: DoubleBed,
    [SvgIconType.DoubleSplitBed]: DoubleSplitBed,
    [SvgIconType.DoubleBedKingSize]: DoubleBedKingSize,
    [SvgIconType.BunkBed]: BunkBed,
    [SvgIconType.Delete]: Delete,
};

/** @obsolete Don't use separated hover-icons, use fill="currentColor" inside SVG instead */
export const svgIconsHoversByTypeMap: Partial<Record<SvgIconType, SvgIconType>> = {
    [SvgIconType.Wallet]: SvgIconType.WalletBlue,
    [SvgIconType.QrCode]: SvgIconType.QrCodeBlue,
};
