import React from "react";
import ReactDOM from "react-dom";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import styles from "./FixedButton.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {BookingContext} from "../../providers/Booking/BookingContext";
import {mainSearchAnalyticsEvents} from "../../analytics/mainSearchAnalyticsEvents";
import {useMount} from "@skbkontur/hotel-hooks/react";

const FixedButton = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const {openBookingLightbox} = React.useContext(BookingContext);

    const containerRef = React.useRef<HTMLDivElement>();

    React.useMemo(() => {
        const container = document.createElement("div");
        containerRef.current = document.body.insertBefore(container, null);
    }, []);

    useMount(() => () => {
        const container = containerRef.current;
        container.parentNode.removeChild(container);
    });

    const handleOpenBookingLightbox = () => {
        mainSearchAnalyticsEvents.trackFixedButtonRunSearch();
        openBookingLightbox();
    };

    return ReactDOM.createPortal((
        <div className={styles.container}>
            <div className={styles.button}>
                <PrimaryButton onClick={handleOpenBookingLightbox}>
                    {t("checkAvailability")}
                </PrimaryButton>
            </div>
        </div>
    ), containerRef.current);
};
FixedButton.displayName = "FixedButton";
export default FixedButton;
