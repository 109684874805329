import React from "react";
import {IHourlyBookingAccommodation, IHourlyAccommodationPrice} from "../../data/HourlyBooking";

export interface IHourlyAccommodationsContext {
    selectedAccommodation: IHourlyBookingAccommodation;
    accommodationPrices: IHourlyAccommodationPrice[];
    updateAccommodation: (selectedAccommodation: Partial<IHourlyBookingAccommodation>) => void;
}

export const HourlyAccommodationsContext = React.createContext({} as IHourlyAccommodationsContext);
