import React from "react";
import {BookingContext} from "./BookingContext";
import BookingLightbox from "./BookingLightbox/BookingLightbox";
import {createBooking} from "../../store/booking/bookingActionCreators";
import {clearAccommodations} from "../../store/accommodations/accommodationsActionCreators";
import {useMemoObject} from "../../common/hooks/useMemoObject";
import {useCommonBookingProvider} from "../../hooks/useCommonBookingProvider";
import { BookingKind } from "../../data/BookingKind";

export const BookingProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {
        isBookingLightboxOpened,
        isBookingProcess,
        handleBooking,
        closeBookingLightbox,
        openBookingLightbox
    } = useCommonBookingProvider({
        kind: BookingKind.Daily,
        clearAccommodations,
        createBooking
    });

    return (
        <BookingContext.Provider
            value={useMemoObject({
                isBookingProcess,
                openBookingLightbox,
            })}
        >
            {children}
            {isBookingLightboxOpened && <BookingLightbox onBooking={handleBooking} onClose={closeBookingLightbox} />}
        </BookingContext.Provider>
    );
};
