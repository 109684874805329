import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {People1Icon16Solid} from "@skbkontur/icons/People1Icon16Solid";
import {ChildBabyIcon16Solid} from "@skbkontur/icons/ChildBabyIcon16Solid";

interface IBookingRateItemGuestsProps {
    adultsCount: number;
    childrenCount: number;
}

const BookingRateItemGuests = (props: IBookingRateItemGuestsProps) => {
    const {adultsCount, childrenCount} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const guestsIcons = (
        <>
            {adultsCount} <People1Icon16Solid size={14} />
            {!!childrenCount && (
                <> + {childrenCount} <ChildBabyIcon16Solid size={14} /></>
            )}
        </>
    );

    return (
        <div>
            {t("rates.guests", {reactParams: {0: guestsIcons}})}
        </div>
    );
};
BookingRateItemGuests.displayName = "BookingRateItemGuests";
export default BookingRateItemGuests;
