module.exports = {
    /** "is..." methods for NODE_ENV */
    isDev: () => process.env.NODE_ENV === "development",
    isProd: () => process.env.NODE_ENV === "production",

    /** "is..." methods for  stageENV */
    isStageTest: () => process.env.stageENV === "test",
    isStageProd: () => process.env.stageENV === "prod",
    isStageLocal: () => process.env.stageENV === "__local_test__",

    /** "is..." methods for others */
    isFuncTest: () => !!process.env.enableReactTesting,
    isWdyrMode: () => !!process.env.enableWdyr,
    isCloud: () => process.env.isCloud === "true",

    /** Get methods */
    getStageENV: () => process.env.stageENV,
    getRecaptchaSiteKey: () => process.env.RECAPTCHA_SITE_KEY,
    getHost: () => process.env.HOST,

    processEnvCustomNames: [
        "NODE_ENV",
        "stageENV",
        "enableReactTesting",
        "enableWdyr",
        "RECAPTCHA_SITE_KEY"
    ]
}
