import React from "react";
import ReactDOM from "react-dom";
import {WidgetSettingsInitializer} from "./widget/settings/WidgetSettingsInitializer";
import WidgetApp from "./widget/WidgetApp";
import WidgetStore from "./widget/WidgetStore";
import {IWidget, IWidgetInitialSettings} from "./widget/settings/WidgetSettingsTypes";
import {Upgrade} from "@skbkontur/react-ui/lib/Upgrades";
import {tracker} from "@skbkontur/km-tracker";
import {WidgetSettingsRoots} from "./widget/settings/WidgetSettingsRoots";
import {getStageENV, isStageLocal} from "@skbkontur/hotel-process-env";
import {trackerENVType} from "@skbkontur/km-tracker/dist/typings/src/resourses/endpoints";
import {initSentry} from "@skbkontur/hotel-sentry";
import {createBrowserHistory} from "history";

/** To override user site styles */
Upgrade.setSpecificityLevel(1);
module?.hot?.accept();

const widgetAppRef = React.createRef<WidgetApp>();
const widgetSettingsInitializer = new WidgetSettingsInitializer();

const history = createBrowserHistory();

export const init = (initialSettings: IWidgetInitialSettings, baseUrl?: string) => {
    if (initialSettings?.version === "2") {
        widgetSettingsInitializer.initializeMultiple(initialSettings);
        WidgetStore.baseUrl = initialSettings.baseUrl.replace(/\/$/, "");
    } else {
        widgetSettingsInitializer.initializeSingle(initialSettings);
        WidgetStore.baseUrl = baseUrl.replace(/\/$/, "");
    }

    tracker.initialize({
        siteID: 154,
        trackerENV: getStageENV() as trackerENVType,
        options: {catchPageVisits: true, catchUnloadEvent: true},
        devMode: isStageLocal()
    });

    initSentry({
        options: {
            dsn: "https://e4f2ddb21207410aa0af246214fa95d0@sentry.kontur.host/301"
        },
        useProfiling: false,
        reactRouterOptions: {history}
    });

    try {
        if (widgetSettingsInitializer.validator.isValidationOk()) {
            const {hotelId} = WidgetStore.settings;
            const appRootId = WidgetSettingsRoots.getAppRootId(hotelId);

            if (!document.getElementById(appRootId)) {
                // eslint-disable-next-line no-console
                console.log("Widget settings validation is OK");

                const appRoot = WidgetSettingsRoots.createAppRoot(hotelId);
                ReactDOM.render(
                    <WidgetApp ref={widgetAppRef}/>,
                    appRoot
                );
            } else {
                // eslint-disable-next-line no-console
                console.log("Widget already exist - skip initializing, just update the current widget...");
                widgetAppRef.current?.refreshApp();
            }
        }
    } catch (error) {
        WidgetStore.settings.hooks?.onError(error);
        throw error;
    }
};

export const add = (widget: IWidget): string => {
    const {type, appearance} = widget;

    if (!widgetSettingsInitializer.validator.isValidType(type)) return;

    if (WidgetStore.isWidgetTypeExists(type)) {
        // eslint-disable-next-line no-console
        console.error(`Widget type "${type}" already exists`);
        return;
    }

    const widgetId = widgetSettingsInitializer.addWidgetItem(WidgetStore.settings.hotelId, type, appearance);
    widgetAppRef.current?.refreshApp();

    return widgetId;
};

export const isExists = (widgetId: string): boolean => (
    WidgetStore.isWidgetIdExists(widgetId)
);

export const remove = (widgetId: string): string => {
    if (!isExists(widgetId)) return;

    WidgetStore.removeElement(widgetId);
    widgetAppRef.current?.refreshApp();

    return widgetId;
};

export const update = (widgetId?: string): string => {
    if (!widgetId) {
        widgetAppRef.current?.refreshApp();
        WidgetStore.settings.widgets.forEach(widget => {
            widget.ref.current.refresh();
        });
        return WidgetSettingsRoots.getAppRootId(WidgetStore.settings.hotelId);
    }

    if (isExists(widgetId)) {
        WidgetStore.getWidgetById(widgetId)?.ref.current.refresh();
        return widgetId;
    }
};
