import {IBookingAccommodation} from "../../../data/Booking";
import {IAccommodationUpdateParams} from "../AccommodationsContext";
import {
    IAccommodationsMapParams,
    IAccommodationsPricesMap,
    ISelectedAccommodationBooking,
    ISelectedAccommodationsMap,
} from "../AccommodationsMaps";
import {ICurrency} from "../../../types/Currency";
import {Currency} from "../../../common/helpers/Currency";
import {AccommodationsPricesHelper} from "./AccommodationsPricesHelper";
import {HotelFeatureAdditionType, IHotelFeature} from "../../../data/HotelFeature";
import {createMapBy} from "../../../common/helpers/arrayUtils";

// TODO Написать тесты

export class SelectedAccommodationsHelper {
    static toBookingAccommodations = (map: ISelectedAccommodationsMap): IBookingAccommodation[] =>
        Object.keys(map).reduce((acc, roomCategoryId) => {
            const roomCategoryMap = map[roomCategoryId];
            Object.keys(roomCategoryMap).forEach((occupancyIndex: string) => {
                roomCategoryMap[occupancyIndex].forEach((accommodation: IBookingAccommodation) => {
                    acc.push({
                        ...accommodation,
                        roomCategoryId,
                    });
                });
            }, acc);
            return acc;
        }, [] as IBookingAccommodation[]);

    static toSelectedAccommodationBookings = (map: ISelectedAccommodationsMap): ISelectedAccommodationBooking[] =>
        Object.keys(map).reduce((acc, roomCategoryId) => {
            const roomCategoryMap = map[roomCategoryId];
            Object.keys(roomCategoryMap).forEach((occupancyIndex: string) => {
                roomCategoryMap[occupancyIndex].forEach((accommodation: IBookingAccommodation, index: number) => {
                    acc.push({
                        accommodation,
                        params: {
                            roomCategoryId,
                            occupancyIndex: Number(occupancyIndex),
                            index,
                        },
                    });
                });
            });
            return acc;
        }, [] as ISelectedAccommodationBooking[]);

    static addAccommodation = (
        map: ISelectedAccommodationsMap,
        params: IAccommodationUpdateParams
    ): ISelectedAccommodationsMap => {
        const {roomCategoryId, occupancyIndex, accommodation} = params;
        const roomCategoryMap = map[roomCategoryId] || {};
        const occupancyAccommodations = roomCategoryMap[occupancyIndex] || [];
        return {
            ...map,
            [roomCategoryId]: {
                ...roomCategoryMap,
                [occupancyIndex]: [...occupancyAccommodations, accommodation],
            },
        };
    };

    static removeAccommodation = (
        map: ISelectedAccommodationsMap,
        params: IAccommodationsMapParams
    ): ISelectedAccommodationsMap => {
        const {roomCategoryId, occupancyIndex, index} = params;
        const roomCategoryMap = map[roomCategoryId] || {};
        const occupancyAccommodations = [...roomCategoryMap[occupancyIndex]] || [];
        occupancyAccommodations.splice(index, 1);
        return {
            ...map,
            [roomCategoryId]: {
                ...roomCategoryMap,
                [occupancyIndex]: occupancyAccommodations,
            },
        };
    };

    static updateAccommodation = (
        map: ISelectedAccommodationsMap,
        params: IAccommodationUpdateParams
    ): ISelectedAccommodationsMap => {
        const {roomCategoryId, occupancyIndex, index, accommodation} = params;
        const roomCategoryMap = map[roomCategoryId] || {};
        const occupancyAccommodations = roomCategoryMap[occupancyIndex] || [];
        return {
            ...map,
            [roomCategoryId]: {
                ...roomCategoryMap,
                [occupancyIndex]: occupancyAccommodations.map((item, i) => (i === index ? accommodation : item)),
            },
        };
    };

    static getBusyAccommodationsCount = (map: ISelectedAccommodationsMap, roomCategoryId: string): number => {
        const roomCategoryMap = map[roomCategoryId];
        if (!roomCategoryMap) {
            return 0;
        }
        return Object.keys(roomCategoryMap).reduce((acc, occupancyIndex) => {
            const occupancyAccommodations = roomCategoryMap[occupancyIndex] || [];
            return acc + occupancyAccommodations.length;
        }, 0);
    };

    static getMinRatesSum = (map: ISelectedAccommodationsMap, pricesMap: IAccommodationsPricesMap): ICurrency =>
        Object.keys(map).reduce(
            (sum, roomCategoryId) =>
                Object.keys(map[roomCategoryId]).reduce((sum, occupancyIndex) => {
                    const accommodations = map[roomCategoryId][occupancyIndex];
                    const accommodationsCount = accommodations.length;
                    if (!accommodationsCount) return sum;

                    const ratesMap = pricesMap?.[roomCategoryId]?.[occupancyIndex];
                    const minRatesSum = AccommodationsPricesHelper.getMinRatesPrice(ratesMap);
                    return Currency.sum([sum, Currency.multiply(minRatesSum, accommodationsCount)]);
                }, sum),
            Currency.zero()
        );

    static getTotalPrice = (
        bookings: ISelectedAccommodationBooking[],
        pricesMap: IAccommodationsPricesMap
    ): ICurrency =>
        Currency.sum(
            bookings.map(({params, accommodation}) => {
                const {rateId} = accommodation;
                const {roomCategoryId, occupancyIndex} = params;
                return pricesMap[roomCategoryId][occupancyIndex][rateId].price;
            })
        );

    static getHotelFeaturesPrice = (
        selectedBookings: ISelectedAccommodationBooking[],
        hotelFeatures: IHotelFeature[]
    ): ICurrency => {
        const hotelFeaturesMap = createMapBy(hotelFeatures, hf => hf.id);
        return selectedBookings.reduce((acc, booking) => {
            const bookingFeaturesIds = Object.keys(booking.accommodation.bookingFeatures);
            const bookingFeaturesCost = bookingFeaturesIds.map(bookingFeatureId => {
                const hotelFeatureCount = booking.accommodation.bookingFeatures[bookingFeatureId];
                const {cost, type} = hotelFeaturesMap[bookingFeatureId];

                const guestsCount = booking.accommodation.adultsCount + (booking.accommodation.childrenCount || 0);
                const costByGuestsCount = Currency.multiply(
                    cost,
                    type === HotelFeatureAdditionType.ByDayAndGuest ? guestsCount : 1
                );

                return Currency.multiply(costByGuestsCount, hotelFeatureCount);
            });

            return Currency.sum([acc, ...bookingFeaturesCost]);
        }, Currency.zero());
    };

    static getTotalPrepayment = (
        bookings: ISelectedAccommodationBooking[],
        pricesMap: IAccommodationsPricesMap
    ): ICurrency =>
        Currency.sum(
            bookings.map(({params, accommodation}) => {
                const {rateId} = accommodation;
                const {roomCategoryId, occupancyIndex} = params;
                return pricesMap[roomCategoryId][occupancyIndex][rateId].prepaymentSum;
            })
        );

    static isRatesFilled = (bookings: IBookingAccommodation[]) => {
        return bookings.every(b => !!b.rateId);
    };
}
