import React from "react";
import {IBookingAccommodation} from "../../../../../../data/Booking";
import {AccommodationsContext} from "../../../../../Accommodations/AccommodationsContext";
import {TranslationNamespaces} from "../../../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {Gapped} from "@skbkontur/react-ui";
import {useRoomCategory} from "../../../../../../hooks/useRoomCategory";
import {RoomCategorySearchParamsContext} from "../../../../../SearchParams/SearchParamsContext";
import BookingAccommodationsAdultsCountSelect from "../../Selects/BookingAccommodationsAdultsCountSelect";
import BookingAccommodationsChildrenCountSelect from "../../Selects/BookingAccommodationsChildrenCountSelect";
import {AccommodationsVariantsHelper} from "../../../../../Accommodations/helpers/AccommodationsVariantsHelper";
import {FREE_CHILDREN_COUNT, MIN_ADULTS_COUNT} from "../../../../../../data/HotelInfo";
import {bookingLightboxOccupancyAnalyticsEvents} from "../../../../../../analytics/bookingLightboxOccupancyAnalyticsEvents";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";

interface IBookingAccommodationsOccupancyItemProps {
    index: number;
    occupancyIndex: number;
    accommodation: Partial<IBookingAccommodation>;
    disabled: boolean;
}

const BookingAccommodationsOccupancyItem = (props: IBookingAccommodationsOccupancyItemProps) => {
    const {accommodation, index, occupancyIndex, disabled} = props;
    const {roomCategoryId, adultsCount, childrenCount} = accommodation;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {params: {kidsCount}} = React.useContext(RoomCategorySearchParamsContext);

    const {updateAccommodation, accommodationsVariantsMap} = React.useContext(AccommodationsContext);
    const occupancyVariants = accommodationsVariantsMap?.[roomCategoryId]?.[occupancyIndex];

    const roomCategory = useRoomCategory(roomCategoryId);

    const update = (accommodation: Partial<IBookingAccommodation>) => {
        updateAccommodation({
            roomCategoryId,
            occupancyIndex,
            index,
            accommodation
        });
    };

    const onAdultsCountChange = (adultsCount: number) => {
        bookingLightboxOccupancyAnalyticsEvents.trackAdultsCountChange(adultsCount);
        update({...accommodation, adultsCount});
    };
    const onChildrenCountChange = (childrenCount: number) => {
        bookingLightboxOccupancyAnalyticsEvents.trackChildrenCountChange(childrenCount);
        update({...accommodation, childrenCount});
    };

    const adultsCountVariants = AccommodationsVariantsHelper.getAdultsCountVariants(occupancyVariants);
    const childrenCountVariants = AccommodationsVariantsHelper.getChildrenCountVariants(occupancyVariants, adultsCount);

    const placesCount = RoomCategoryHelper.getGuestsCount(roomCategory);
    const maxChildrenCount = placesCount - MIN_ADULTS_COUNT + FREE_CHILDREN_COUNT;
    const showChildrenCount = maxChildrenCount > 0 && !!kidsCount;

    return (
        <Gapped gap={8}>
            {t("accommodations.guests")}
            <BookingAccommodationsAdultsCountSelect
                value={adultsCount}
                onChange={onAdultsCountChange}
                disabled={disabled}
                availableVariants={adultsCountVariants}
            />
            {showChildrenCount && (
                <BookingAccommodationsChildrenCountSelect
                    value={childrenCount}
                    onChange={onChildrenCountChange}
                    disabled={disabled}
                    availableVariants={childrenCountVariants}
                    maxCount={maxChildrenCount}
                />
            )}
        </Gapped>
    );
};
BookingAccommodationsOccupancyItem.displayName = "BookingAccommodationsOccupancyItem";
export default React.memo(BookingAccommodationsOccupancyItem);
