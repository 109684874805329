import React from "react";
import {IRoomCategory, RoomType} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {BuildingHomeIcon16Light} from "@skbkontur/icons/BuildingHomeIcon16Light";
import styles from "./RoomCategoryCharacteristics.scss";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";

interface IRoomCategoryCharacteristicsRoomsProps {
    category: IRoomCategory;
}

const RoomCategoryCharacteristicsRooms = (props: IRoomCategoryCharacteristicsRoomsProps) => {
    const {category: {rooms}} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    if (!rooms?.length)
        return null;

    const roomCountsMap: Record<RoomType, number> = rooms.reduce((roomsMap, {type}) => ({
        ...roomsMap,
        [type]: (roomsMap[type] || 0) + 1
    }), {} as Record<RoomType, number>);

    return (
        <span>
            <BuildingHomeIcon16Light className={styles.roomIcon}/>
            <span>{t("roomsCount", {count: rooms.length})}:&nbsp;</span>
            {Object.keys(roomCountsMap).map((roomType, index) => (
                <span key={roomType}>
                    {roomCountsMap[roomType] > 1
                        ? t(`roomTypesTagsWithCount.${roomType}`, {count: roomCountsMap[roomType]})
                        : t(`roomTypesTags.${roomType}`)}

                    {Object.keys(roomCountsMap).length !== index + 1 && <>,&nbsp;</>}
                </span>
            ))}
        </span>
    );
};
RoomCategoryCharacteristicsRooms.displayName = "RoomCategoryCharacteristicsRooms";
export default RoomCategoryCharacteristicsRooms;