import React from "react";
import {IBedStructure, IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import RoomCategoryCharacteristicsIcons from "./components/RoomCategoryCharacteristicsIcons";
import {Tooltip} from "@skbkontur/react-ui";
import RoomCategoryCharacteristicsHint from "./components/RoomCategoryCharacteristicsHint";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";

interface IRoomCategoryCharacteristicsProps {
    category: IRoomCategory;
    hideArea?: boolean;
    modifyImageUrl?: (url: string) => string;
}

const RoomCategoryCharacteristics = (props: IRoomCategoryCharacteristicsProps) => {
    const {category, hideArea, modifyImageUrl} = props;
    const {rooms, roomCategoryType, placesMin, placesMax} = category;

    const allBeds = rooms?.reduce((beds, room) => [
        ...beds,
        ...(room.beds?.filter(bed => bed.type) || [])
    ], [] as IBedStructure[]);

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const renderTooltip = () => (
        RoomCategoryHelper.isHostel(roomCategoryType) ? (
            <div>
                {placesMax === placesMin
                    ? t("hostelPlacesCount", {count: placesMin})
                    : t("hostelPlacesCountRange", {min: placesMin, max: placesMax})}
            </div>
        ) : <RoomCategoryCharacteristicsHint category={category} beds={allBeds} modifyImageUrl={modifyImageUrl} />
    )

    return (
         !!allBeds?.length || (RoomCategoryHelper.isHostel(roomCategoryType) && placesMin) ? (
             <Tooltip pos="top left" render={renderTooltip}>
                 <RoomCategoryCharacteristicsIcons
                     category={category}
                     hideArea={hideArea}
                     beds={allBeds}
                     modifyImageUrl={modifyImageUrl}
                 />
             </Tooltip>
         ) : (
             <RoomCategoryCharacteristicsIcons
                 category={category}
                 hideArea={hideArea}
                 beds={allBeds}
                 modifyImageUrl={modifyImageUrl}
             />
         )
    );
};
RoomCategoryCharacteristics.displayName = "RoomCategoryCharacteristics";
export default RoomCategoryCharacteristics;