import React from "react";
import {ICurrency} from "../../../../common/helpers/Currency";
import styles from "./BookingLightboxSumWithLabel.scss";
import {SumWithLocale} from "../../../../components/SumWithLocale/SumWithLocale";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {RoomCategorySearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import DateHelper from "../../../../helpers/DateHelper";
import {DeviceContext} from "../../../Device/DeviceContext";
import {Gapped} from "@skbkontur/react-ui";

interface IBookingLightboxSumWithLabelProps {
    sum: ICurrency;
    isHostel: boolean;
    accommodationsCount?: number;
    shortOnMobile?: boolean;
}

const BookingLightboxSumWithLabel = (props: IBookingLightboxSumWithLabelProps) => {
    const {sum, accommodationsCount, isHostel, shortOnMobile} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {isMobileMode} = React.useContext(DeviceContext);

    const {params: {fromDate, toDate}} = React.useContext(RoomCategorySearchParamsContext);
    const nightsCount = DateHelper.getDiffByDays(fromDate, toDate);

    let label: React.ReactNode;
    const isShorten = isMobileMode && shortOnMobile;

    switch (true) {
        case !accommodationsCount:
            label = t("total.forNights", {nightsCount});
            break;
        case isHostel && isShorten:
            label = (
                <Gapped gap={3} vertical>
                    {t("total.nights", {nightsCount})}
                    {t("total.places", {placeCount: accommodationsCount})}
                </Gapped>
            );
            break;
        case isHostel:
            label = t("total.forNightsForPlaces", {nightsCount, placeCount: accommodationsCount});
            break;
        case isShorten:
            label = (
                <Gapped gap={3} vertical>
                    {t("total.nights", {nightsCount})}
                    {t("total.rooms", {roomsCount: accommodationsCount})}
                </Gapped>
            );
            break;
        default:
            label = t("total.forNightsForRooms", {nightsCount, roomsCount: accommodationsCount});
    }

    return (
        <div>
            <div className={styles.sum}>
                <SumWithLocale sum={sum} />
            </div>
            <div className={styles.label}>
                {label}
            </div>
        </div>
    );
};
BookingLightboxSumWithLabel.displayName = "BookingLightboxSumWithLabel";
export default BookingLightboxSumWithLabel;
