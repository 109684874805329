import React from "react";
import RoomAvailabilityCalendar from "../RoomAvailabilityCalendar/RoomAvailabilityCalendar";
import {RoomCategorySearchParamsContext} from "../../providers/SearchParams/SearchParamsContext";
import styles from "./RoomAvailabilityCalendarWithFilter.scss";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import RoomAvailabilityCalendarSelect from "./RoomAvailabilityCalendarSelect";
import RoomAvailabilityCalendarParameters from "./RoomAvailabilityCalendarParameters";
import cn from "classnames";
import {CalendarContext} from "../../providers/Calendar/CalendarContext";
import {WithProvider} from "../../common/components/WithProvider/WithProvider";
import CalendarProvider from "../../providers/Calendar/CalendarProvider";
import BookingLightboxContainer from "../../providers/Booking/BookingLightbox/BookingLightboxContainer";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {getItemById} from "@skbkontur/hotel-data/utils";

const getInitialRoomCategory = (initialRoomCategoryId: string, roomCategories: IRoomCategory[]): IRoomCategory => (
    initialRoomCategoryId
        ? roomCategories.find(item => item.id === initialRoomCategoryId)
        : roomCategories[0]
);

const RoomAvailabilityCalendarWithFilter = () => {
    const {isAlwaysOneMonth, isTwoMonthsWidget, calendarMode} = React.useContext(CalendarContext);
    const {params, setParams} = React.useContext(RoomCategorySearchParamsContext);
    const {roomCategoryId, calendarRoomCategoryId} = params;

    const roomCategories = useSelector((state: IAppState) => state.roomCategories.roomCategories);
    const isRoomCategoriesLoaded = !!roomCategories.length;
    const roomCategory = getItemById(roomCategories, calendarRoomCategoryId);

    React.useEffect(() => {
        if (isRoomCategoriesLoaded) {
            setParams(params => ({
                ...params,
                calendarRoomCategoryId: getInitialRoomCategory(roomCategoryId, roomCategories).id
            }))
        }
    }, [isRoomCategoriesLoaded]);

    const roomCategoryChange = (newRoomCategoryId: string) => {
        setParams(params => ({
            ...params,
            calendarRoomCategoryId: newRoomCategoryId
        }))
    };

    return (
        <div className={cn(styles.container, {
            [styles.singleMonthContainer]: isAlwaysOneMonth,
            [styles.twoMonthsWidgetContainer]: isTwoMonthsWidget
        })}>
            <BookingLightboxContainer
                className={cn(styles.filterContainer, {
                    [styles.filterContainerSingleWidget]: isAlwaysOneMonth
                })}
            >
                <RoomAvailabilityCalendarSelect
                    categories={roomCategories}
                    roomCategoryId={calendarRoomCategoryId}
                    onValueChange={roomCategoryChange}
                />
                {roomCategory && <RoomAvailabilityCalendarParameters roomCategory={roomCategory}/>}
            </BookingLightboxContainer>
            <RoomAvailabilityCalendar
                searchParams={params}
                roomCategoryId={calendarRoomCategoryId}
                calendarMode={calendarMode}
            />
        </div>
    );
};
RoomAvailabilityCalendarWithFilter.displayName = "RoomAvailabilityCalendarWithFilter";
export default WithProvider(RoomAvailabilityCalendarWithFilter, CalendarProvider);
