import React from "react";
import styles from "./CalendarControls.scss";
import {ArrowCRightIcon16Regular} from "@skbkontur/icons/ArrowCRightIcon16Regular";
import {ArrowCLeftIcon16Regular} from "@skbkontur/icons/ArrowCLeftIcon16Regular";

interface ICalendarControlsProps {
    leftArrowIsHidden?: boolean;
    rightArrowIsHidden?: boolean;
    onLeftArrowClick: () => void;
    onRightArrowClick: () => void;
}

const CalendarControls = (props: ICalendarControlsProps) => {
    const {
        leftArrowIsHidden,
        rightArrowIsHidden,
        onLeftArrowClick,
        onRightArrowClick
    } = props;

    return (
        <div className={styles.calendarControls}>
            <div className={styles.buttonWrapper}>
                {!leftArrowIsHidden && (
                    <button
                        className={styles.button}
                        onClick={onLeftArrowClick}
                    >
                        <ArrowCLeftIcon16Regular size={14} />
                    </button>
                )}
            </div>
            <div className={styles.buttonWrapper}>
                {!rightArrowIsHidden && (
                    <button
                        className={styles.button}
                        onClick={onRightArrowClick}
                    >
                        <ArrowCRightIcon16Regular size={14} />
                    </button>
                )}
            </div>
        </div>
    );
};
CalendarControls.displayName = "CalendarControls";
export default CalendarControls;
