import {IWidget, IWidgetRef, IWidgetRefresh, IWidgetSettingsContainer, WidgetType} from "../widget/settings/WidgetSettingsTypes";
import {Guid} from "../common/helpers/Guid";
import React from "react";

export class Widget implements IWidget {
    appearance: IWidgetSettingsContainer;
    id: string;
    ref: IWidgetRef;
    type: WidgetType;

    constructor(type: WidgetType, appearance: IWidgetSettingsContainer) {
        this.id = this.createWidgetId(type);
        this.appearance = appearance;
        this.ref = React.createRef<IWidgetRefresh>();
        this.type = type;
    }

    private createWidgetId = (widgetType: WidgetType): string => (
        widgetType + "_" + Guid.getUuid(4, 16)
    );
}
