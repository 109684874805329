import React from "react";
import styles from "./RoomAvailabilityCalendarParameters.scss";
import PlacementParameters from "../PlacementParameters/PlacementParameters";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";

interface IRoomAvailabilityCalendarParametersProps {
    roomCategory: IRoomCategory;
}

const RoomAvailabilityCalendarParameters = (props: IRoomAvailabilityCalendarParametersProps) => {
    const {roomCategory} = props;
    const {placesMin, placesMax, area} = roomCategory;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const hasPlaces = !!placesMin || !!placesMax;

    if (!hasPlaces && !area) return null;

    return (
        <div className={styles.parametersRow}>
            {hasPlaces && (
                <div className={styles.parameter}>
                    <div className={styles.parameterName}>
                        {t("roomCategory.places")}
                    </div>
                    <PlacementParameters roomCategory={roomCategory} isBold />
                </div>
            )}
            {area && (
                <div className={styles.parameter}>
                    <div className={styles.parameterName}>
                        {t("roomCategory.area")}
                    </div>
                    <b>
                        {area} {t("roomCategory.areaSquareMeter")}
                    </b>
                </div>
            )}
        </div>
    );
};
RoomAvailabilityCalendarParameters.displayName = "RoomAvailabilityCalendarParameters";
export default RoomAvailabilityCalendarParameters;
