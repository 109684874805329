import {IHotelFeature} from "../data/HotelFeature";
import {IWithOrder} from "../types/WithOrder";
// TODO скопировано из Отеля

export class Compare {
    static byOrder = <TItem extends IWithOrder>(a: TItem, b: TItem) => {
        return (a?.order || 0) - (b?.order || 0);
    };

    static asc = (a: number, b: number) => a - b;

    static strings = (a: string, b: string) => (
        a === b ? 0 : ((a || "").toUpperCase() > (b || "").toUpperCase() ? 1 : -1)
    );

    static byIncludingInRate = (a: IHotelFeature, b: IHotelFeature, rateId: string) => {
        if (a.rateIds.includes(rateId)) return 1;
        if (b.rateIds.includes(rateId)) return -1;

        return Compare.strings(a.name, b.name);
    };
}
