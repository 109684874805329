import {Gapped} from "@skbkontur/react-ui";
import React from "react";
import {ISelectedAccommodationBooking} from "../../../Accommodations/AccommodationsMaps";
import RoomCategoryCard from "../../../../components/RoomCategoryCard/RoomCategoryCard";
import {useRoomCategory} from "../../../../hooks/useRoomCategory";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {BookingLightboxStep, BookingLightboxStepsContext} from "../Steps/BookingLightboxStepsContext";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import BookingRatesAccommodationList from "./BookingRatesAccommodationList";
import styles from "./BookingRates.scss";
import {DeviceContext} from "../../../Device/DeviceContext";
import {bookingLightboxRatesAnalyticsEvents} from "../../../../analytics/bookingLightboxRatesAnalyticsEvents";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";

interface IBookingRatesAccommodationProps {
    listIndex: number;
    accommodationBooking: ISelectedAccommodationBooking;
    single: boolean;
}

const BookingRatesAccommodation = (props: IBookingRatesAccommodationProps) => {
    const {accommodationBooking, listIndex, single} = props;
    const {params} = accommodationBooking;
    const {roomCategoryId, occupancyIndex} = params;

    const {isDesktopMode} = React.useContext(DeviceContext);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {name} = useRoomCategory(roomCategoryId);
    const {goToStep} = React.useContext(BookingLightboxStepsContext);
    const {accommodationsPricesMap} = React.useContext(AccommodationsContext);

    const changeVariant = () => {
        bookingLightboxRatesAnalyticsEvents.trackGoToOccupancies();
        goToStep(BookingLightboxStep.SelectAccommodations, {roomCategoryId});
    };

    const prices = Object.values(accommodationsPricesMap[roomCategoryId][occupancyIndex]);
    const title = single ? name : `${listIndex + 1}. ${name}`;

    return (
        <div className={styles.accommodation}>
            <Gapped gap={isDesktopMode ? 20 : 0} vertical>
                <RoomCategoryCard id={roomCategoryId} title={title}>
                    <PrimaryButton use="default" onClick={changeVariant}>
                        {t("rates.changeVariant")}
                    </PrimaryButton>
                </RoomCategoryCard>
                <BookingRatesAccommodationList
                    selectedAccommodation={accommodationBooking}
                    prices={prices}
                />
            </Gapped>
        </div>
    );
};
BookingRatesAccommodation.displayName = "BookingRatesAccommodation";
export default BookingRatesAccommodation;
