import React from "react";
import styles from "./HourlyBookingRates.scss";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";
import HourlyObjectsCard from "../../../../components/HourlyObjectsCard/HourlyObjectsCard";
import {useHourlyObjectById} from "../../../../hooks/useHourlyObjectById";
import HourlyBookingRatesAccommodationList from "./HourlyBookingRatesAccommodationList";
import HourlyBookingLightboxOrderBook from "./HourlyBookingLightboxOrderBook";
import HourlyBookingRatesFlipper from "../Flipper/HourlyBookingRatesFlipper";

const HourlyBookingRates = () => {
    const {selectedAccommodation} = React.useContext(HourlyAccommodationsContext);
    const {hourlyObjectItemId, hourlyObjectId, hourlyRateId} = selectedAccommodation;

    const hourlyObject = useHourlyObjectById(hourlyObjectId);

    return (
        <HourlyBookingRatesFlipper
            isLast
            key={selectedAccommodation.renderKey}
            renderKey={selectedAccommodation.renderKey}
        >
            <div className={styles.accommodation}>
                <HourlyObjectsCard
                    autoPlaySlider
                    hourlyObject={hourlyObject}
                    hourlyObjectItemId={hourlyObjectItemId}
                />
                <HourlyBookingRatesAccommodationList />
                {hourlyRateId && <HourlyBookingLightboxOrderBook />}
            </div>
        </HourlyBookingRatesFlipper>
    );
};
HourlyBookingRates.displayName = "HourlyBookingRates";
export default React.memo(HourlyBookingRates);
