import React from "react";
import {FlipperHelper} from "../../../../common/components/Flipper/FlipperHelper";
import styles from "./BookingLightboxFlipper.scss";
import FlippedElement from "../../../../common/components/Flipper/Element/FlippedElement";
import {BookingLightboxFlipperHelper} from "./BookingLightboxFlipperHelper";
import {BookingLightboxFlipperStaggers} from "./BookingLightboxFlipperStaggers";

interface IBookingLightboxFlipperRateProps {
    renderKey: string;
    rateId: string;
}

const BookingLightboxFlipperRate = (props: React.PropsWithChildren<IBookingLightboxFlipperRateProps>) => {
    const {renderKey, rateId, children} = props;

    return (
        <FlippedElement
            flipId={rateId}
            shouldFlip={BookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}
            onAppear={FlipperHelper.onAppear(styles.fadeIn400, 400)}
            stagger={BookingLightboxFlipperStaggers.Rate}
            withDiv
            withContext
        >
            {children}
        </FlippedElement>
    );
};
BookingLightboxFlipperRate.displayName = "BookingLightboxFlipperRate";
export default BookingLightboxFlipperRate;
