import React from "react";
import {Button, ButtonProps} from "@skbkontur/react-ui";
import styles from "./PrimaryButton.scss";
import cn from "classnames";

const PrimaryButton = (props: React.PropsWithChildren<ButtonProps>) => {
    const {children, className, ...rest} = props;

    return (
        <Button className={cn(styles.button, className)} use="pay" size="medium" {...rest}>
            {children}
        </Button>
    );
};
PrimaryButton.displayName = "PrimaryButton";
export default PrimaryButton;
