import React from "react";
import {Slider} from "@skbkontur/hotel-components/slider";
import {GalleryContext} from "../../providers/Gallery/GalleryContext";
import {ImageApi} from "../../api/image";
import {IImageMeta} from "@skbkontur/hotel-data/image";

interface ISliderWithGalleryProps {
    imageMetas: IImageMeta[];
    autoPlay?: boolean;
    emptyImage: string;
}

const SliderWithGallery = (props: ISliderWithGalleryProps) => {
    const {
        autoPlay,
        imageMetas,
        emptyImage
    } = props;

    const {isLightboxOpened, openGallery} = React.useContext(GalleryContext);

    const imageThumbnails = ImageApi.getThumbnailsFromMetas(imageMetas);
    const imagePhotos = ImageApi.getImagesFromMetas(imageMetas);

    const openSliderGallery = (startImageIndex: number) => {
        openGallery(imagePhotos, startImageIndex);
    }

    return (
        <Slider
            autoPlay={autoPlay && !isLightboxOpened}
            images={imageThumbnails}
            emptyImage={emptyImage}
            inGallery
            onSlideClick={openSliderGallery}
        />
    );
};
SliderWithGallery.displayName = "SliderWithGallery";
export default SliderWithGallery;