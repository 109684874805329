import React from "react";
import {CalendarMode} from "../../data/Calendar/Calendar";

export interface ICalendarContext {
    calendarMode: CalendarMode;
    isAlwaysOneMonth?: boolean;
    isTwoMonthsWidget?: boolean;
    isTwoMonthsRegular?: boolean;
}

export const CalendarContext = React.createContext({} as ICalendarContext);
