import {IHotelFeature} from "../../data/HotelFeature";
import React from "react";
import styles from "./BookingRatesHotelFeatures.scss";
import BookingRatesHotelFeatureCost from "./BookingRatesHotelFeatureCost";
import BookingRatesHotelFeatureIncludedInRate from "./BookingRatesHotelFeatureIncludedInRate";
import BookingRatesHotelFeatureInfoBlock from "./BookingRatesHotelFeatureInfoBlock";
import {HotelFeaturesMap} from "../../data/Booking";

interface IBookingRatesHotelFeatureItemProps {
    hotelFeature: IHotelFeature;
    rateId: string;
    guestsCount?: number;
    hotelFeaturesMap: HotelFeaturesMap;
    updateHotelFeature: (hotelFeatureId: string, newCount: number) => void;
}

const BookingRatesHotelFeatureItem = (props: IBookingRatesHotelFeatureItemProps) => {
    const {hotelFeature, rateId, hotelFeaturesMap, guestsCount, updateHotelFeature} = props;
    const {rateIds} = hotelFeature;

    const isIncludedInRate = rateIds.includes(rateId);

    return (
        <div className={styles.item}>
            <BookingRatesHotelFeatureInfoBlock hotelFeature={hotelFeature} />
            {isIncludedInRate ? (
                <BookingRatesHotelFeatureIncludedInRate />
            ) : (
                <BookingRatesHotelFeatureCost
                    hotelFeature={hotelFeature}
                    guestsCount={guestsCount}
                    hotelFeaturesMap={hotelFeaturesMap}
                    updateHotelFeature={updateHotelFeature}
                />
            )}
        </div>
    );
};

BookingRatesHotelFeatureItem.displayName = "BookingRatesHotelFeatureItem";
export default BookingRatesHotelFeatureItem;
