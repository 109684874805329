import React from "react";

type Event = React.KeyboardEvent<HTMLElement> | KeyboardEvent;
type IsKey = (event: Event) => boolean;

export class KeyboardHelper {
    static isKeyEnter: IsKey = e => e.key === "Enter";
    static isKeyEscape: IsKey = e => e.key === "Escape";
    static isKeyArrowUp: IsKey = e => e.key === "ArrowUp" || e.key === "Up" || e.keyCode === 38;
    static isKeyArrowRight: IsKey = e => e.key === "ArrowRight" || e.key === "Right" || e.keyCode === 39;
    static isKeyArrowDown: IsKey = e => e.key === "ArrowDown" || e.key === "Down" || e.keyCode === 40;
    static isKeyArrowLeft: IsKey = e => e.key === "ArrowLeft" || e.key === "Left" || e.keyCode === 37;
    static isLatinSymbol: IsKey = e => /^[a-zA-Z]+$/.test(e.key);
    static isNumber: IsKey = e => /^[0-9]$/.test(e.key);
    static isMinusOrUnderscore: IsKey = e => e.key === "-" || e.key === "_";
    static isRussianSymbol: IsKey = e => /^[а-яА-ЯёЁ]+$/.test(e.key);
}
