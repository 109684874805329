import React from "react";
import {IRoomCategoryAccommodation} from "../../../../../data/Accommodation";
import Title from "../../../../../components/Title/Title";
import BookingRoomCategoryAccommodation from "../RoomCategory/BookingRoomCategoryAccommodation";
import BookingLightboxContainer from "../../BookingLightboxContainer";
import {AccommodationItemsSortType} from "../../BookingLightboxDataHelper";

interface IBookingAccommodationsRoomCategoryProps {
    accommodations: IRoomCategoryAccommodation[];
    title?: React.ReactNode;
    sortItems?: AccommodationItemsSortType;
}

export const getAccommodationId = (roomCategoryId: string) => `Accommodation_${roomCategoryId}`;

const BookingAccommodationsResults = (props: IBookingAccommodationsRoomCategoryProps) => {
    const {title, accommodations, sortItems} = props;

    return (
        <BookingLightboxContainer>
            {title && (
                <Title small>
                    {title}
                </Title>
            )}
            {accommodations.map((accommodation, index) => (
                <BookingRoomCategoryAccommodation
                    key={accommodation.roomCategoryId}
                    accommodation={accommodation}
                    sortItems={sortItems}
                    isLast={index === accommodations.length - 1}
                />
            ))}
        </BookingLightboxContainer>
    );
};
BookingAccommodationsResults.displayName = "BookingAccommodationsResults";
export default BookingAccommodationsResults;
