import React, {DependencyList} from "react";
import {useRef} from "@skbkontur/hotel-hooks/react";

// TODO копия с отеля, объединить после единого фронта
export const useEffectWithoutInitCall = (effect: () => void, deps: DependencyList) => {
    const [isInitialRender, setIsInitialRender] = useRef(true);

    React.useEffect(() => {
        if (isInitialRender.current) {
            setIsInitialRender(false);
        } else {
            effect();
        }
    }, deps);
};
