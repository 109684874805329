import React from "react";
import {Modal} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

interface IBookingLightboxSuccessProps {
    onClose: () => void;
}

const BookingLightboxSuccess = (props: IBookingLightboxSuccessProps) => {
    const {onClose} = props;
    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);
    const isAutoSendConfirmation = useSelector((state: IAppState) => state.hotelInfo.info.autoSendConfirmationEmail);
    const caption = isAutoSendConfirmation
        ? t("weWillContactYouWithConfirmation")
        : t("weWillContactYouNoConfirmation");

    return (
        <Modal disableFocusLock onClose={onClose} width={500}>
            <Modal.Header>{t("thanksForBooking")}</Modal.Header>
            <Modal.Body>{caption}</Modal.Body>
            <Modal.Footer>
                <PrimaryButton use="default" onClick={onClose}>
                    {tcn("buttons.close")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};
BookingLightboxSuccess.displayName = "BookingLightboxSuccess";
export default BookingLightboxSuccess;
