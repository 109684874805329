import {useTranslation} from "@skbkontur/i18n";
import React from "react";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {Measure} from "../../data/HotelFeature";

export interface IMeasureControlProps {
    measure: Measure;
    short?: boolean;
    count?: number;
}

// copied from hotel
const MeasureControl = (props: IMeasureControlProps) => {
    const {measure, short, count = 1} = props;

    const {tcn} = useTranslation(TranslationNamespaces.Common);

    let measureString = tcn("measures.empty");

    switch (measure) {
        case Measure.Counter:
            // always short (шт.)
            measureString = tcn("measures.pieces", {stringFormat: true});
            break;
        case Measure.Day:
            measureString = short ? tcn("measures.short.day") : tcn("measures.day", {count});
            break;
        case Measure.Hour:
            measureString = short ? tcn("measures.short.hour") : tcn("measures.hour", {count});
    }
    return <>{measureString}</>;
};
MeasureControl.displayName = "MeasureControl";
export default MeasureControl;
