import React from "react";
import {IAccommodationPrice} from "../../../../../data/Accommodation";
import {useRate} from "../../../../../hooks/useRate";
import styles from "./BookingRateItem.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import BookingRateItemName from "./BookingRateItemName";
import BookingRateItemConditions from "./BookingRateItemConditions";
import {Gapped} from "@skbkontur/react-ui";
import {IBookingAccommodation} from "../../../../../data/Booking";
import BookingRateItemIncludes from "./BookingRateItemIncludes";
import BookingLightboxSumWithLabel from "../../SumWithLabel/BookingLightboxSumWithLabel";
import {useRoomCategory} from "../../../../../hooks/useRoomCategory";
import BookingRateItemGuests from "./BookingRateItemGuests";
import {DeviceContext} from "../../../../Device/DeviceContext";
import cn from "classnames";
import {bookingLightboxRatesAnalyticsEvents} from "../../../../../analytics/bookingLightboxRatesAnalyticsEvents";
import PrimaryButton from "../../../../../components/PrimaryButton/PrimaryButton";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";

interface IBookingRateItemProps {
    accommodation: IBookingAccommodation;
    price: IAccommodationPrice;
    single: boolean;
    onSelectRate: (rateId: string) => void;
}

const BookingRateItem = (props: IBookingRateItemProps) => {
    const {accommodation, price: priceRate, single, onSelectRate} = props;
    const {adultsCount, childrenCount, renderKey, rateId: accommodationRateId, roomCategoryId} = accommodation;
    const {rateId, price, cancellationPenaltySum, prepaymentSum} = priceRate;

    const {isDesktopMode} = React.useContext(DeviceContext);
    const {name} = useRate(rateId);
    const {roomCategoryType} = useRoomCategory(roomCategoryId);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const selected = accommodationRateId === rateId;
    const captionName = <BookingRateItemName renderKey={renderKey} name={name} />;

    const selectRate = () => onSelectRate(rateId);
    const changeVariant = () => {
        bookingLightboxRatesAnalyticsEvents.trackChangeRate();
        onSelectRate(null);
    };

    return (
        <div className={cn(styles.item, {[styles.selected]: selected})}>
            <div className={styles.conditions}>
                <Gapped gap={isDesktopMode ? 11 : 10} vertical>
                    <div className={styles.caption}>
                        {selected
                            ? captionName
                            : t("rates.name", {reactParams: {0: captionName}, raw: true})}
                    </div>
                    <BookingRateItemIncludes rateId={rateId} />
                    <BookingRateItemConditions
                        rateId={rateId}
                        prepaymentSum={prepaymentSum}
                        cancellationPenaltySum={cancellationPenaltySum}
                    />
                </Gapped>
            </div>
            <div className={styles.summary}>
                <BookingLightboxSumWithLabel
                    sum={price}
                    isHostel={RoomCategoryHelper.isHostel(roomCategoryType)}
                />
                <BookingRateItemGuests
                    adultsCount={adultsCount}
                    childrenCount={childrenCount}
                />
                {!single && (
                    <div className={styles.buttons}>
                        {selected
                            ? <PrimaryButton onClick={changeVariant} use="default">{t("rates.changeRate")}</PrimaryButton>
                            : <PrimaryButton onClick={selectRate}>{t("rates.selectRate")}</PrimaryButton>}
                    </div>
                )}
            </div>
        </div>
    );
};
BookingRateItem.displayName = "BookingRateItem";
export default BookingRateItem;
