import React from "react";
import styles from "./RoomAvailabilityCalendarSelect.scss";
import {FLAT_THEME, Select, ThemeContext, ThemeFactory} from "@skbkontur/react-ui";
import RoomAvailabilityCalendarParameters from "./RoomAvailabilityCalendarParameters";
import cn from "classnames";
import {CalendarContext} from "../../providers/Calendar/CalendarContext";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {createMapBy} from "../../common/helpers/arrayUtils";

interface IRoomAvailabilityCalendarSelectProps {
    categories: IRoomCategory[];
    roomCategoryId: string;
    onValueChange: (newRoomCategoryId: string) => void;
}

const themeForSelect = ThemeFactory.create({
    menuItemHoverBg: "#f0f0f0",
    menuItemSelectedBg: "#e5e5e5",
    btnDefaultActiveBg: "#ffffff"
}, FLAT_THEME);

const RoomAvailabilityCalendarSelect = (props: IRoomAvailabilityCalendarSelectProps) => {
    const {categories, roomCategoryId, onValueChange} = props;

    const {isAlwaysOneMonth} = React.useContext(CalendarContext);
    const categoriesMap = createMapBy(categories, rc => rc.id);

    const items = categories.map(rc => rc.id);

    const renderValue = (roomCategoryId: string) => (
        categoriesMap[roomCategoryId].name
    );

    const renderSelectItem = (roomCategoryId: string) => (
        <div className={styles.selectItem}>
            <div className={styles.selectItemTitle}>{renderValue(roomCategoryId)}</div>
            <RoomAvailabilityCalendarParameters roomCategory={categoriesMap[roomCategoryId]} />
        </div>
    );

    return (
        <ThemeContext.Provider value={themeForSelect}>
            <Select<string>
                className={cn(styles.select, {[styles.fullWidth]: isAlwaysOneMonth})}
                onValueChange={onValueChange}
                value={roomCategoryId}
                items={items}
                renderItem={renderSelectItem}
                renderValue={renderValue}
                size={"medium"}
            />
        </ThemeContext.Provider>
    );
};
RoomAvailabilityCalendarSelect.displayName = "RoomAvailabilityCalendarSelect";
export default RoomAvailabilityCalendarSelect;
