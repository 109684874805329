import React, {useContext} from "react";
import styles from "./CalendarMonth.scss";
import CalendarDay from "./CalendarDay";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {CalendarDayOfWeek, CalendarMonthNames, CalendarMonthDataMapType} from "../../data/Calendar/Calendar";
import cn from "classnames";
import {CalendarHelper} from "../../data/Calendar/CalendarHelper";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";
import {CalendarContext} from "../../providers/Calendar/CalendarContext";

const getDayOfWeekByIndex = (index: number): string => CalendarDayOfWeek[index % 7];

interface ICalendarMonthProps {
    monthNumberFromZero: number;
    year: number;
    isSingleMonth: boolean;
    offsetInMinutes?: number;
    monthData?: CalendarMonthDataMapType;
}

const CalendarMonth = (props: ICalendarMonthProps) => {
    const {
        monthNumberFromZero: month,
        year,
        monthData,
        isSingleMonth,
        offsetInMinutes
    } = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleCalendar);
    const {isTwoMonthsWidget, isTwoMonthsRegular, isAlwaysOneMonth} = useContext(CalendarContext);

    const dateIsDisabled = (day: number): boolean => (
        DateHelper.isBeforeDateByDay(
            DateHelper.getDate(year, month, day, DateFormats.FullDateYearFirst),
            DateHelper.getTodayWithTimezone(DateFormats.FullDateYearFirst, offsetInMinutes),
            DateFormats.FullDateYearFirst
        )
    );

    const containerClassNames = cn(styles.month, {
        [styles.isAlwaysOneMonth]: isAlwaysOneMonth,
        [styles.twoMonthsWidget]: isTwoMonthsWidget,
        [styles.twoMonthsRegular]: isTwoMonthsRegular,
    });

    return (
        <section className={containerClassNames}>
            <div className={styles.monthTitle}>
                {t(`months.${CalendarMonthNames[month]}`)}
            </div>
            <div className={styles.monthGrid}>
                {CalendarHelper.getDaysInMonthArrayWithSpaces(year, month).map((day, index) => (
                    <CalendarDay
                        day={day}
                        description={monthData?.[day]?.description}
                        dayOfWeek={t(`shortDaysOfWeek.${getDayOfWeekByIndex(index)}`)}
                        key={`${getDayOfWeekByIndex(index)}-${day}-${month}`}
                        isSingleMonth={isSingleMonth}
                        disabled={dateIsDisabled(day)}
                    />
                ))}
            </div>
        </section>
    );
};
CalendarMonth.displayName = "CalendarMonth";
export default React.memo(CalendarMonth);
