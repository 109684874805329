import React from "react";
import RoomAvailabilityCalendarWithFilter from "../../../components/RoomAvailabilityCalendarWithFilter/RoomAvailabilityCalendarWithFilter";
import {IWidget, WidgetType} from "../../settings/WidgetSettingsTypes";
import {CalendarMode} from "../../../data/Calendar/Calendar";
import {WidgetValidation} from "../../validation/WidgetValidation";
import {withWidgetContainerAndRefHOC} from "../Widget/withWidgetContainerAndRefHOC";

interface IWidgetCalendarProps {
    widget: IWidget;
}

const WidgetCalendar = (props: IWidgetCalendarProps) => {
    const {widget: {type}} = props;

    const horizontalMode = WidgetValidation.areEqualEnums<WidgetType>(type, WidgetType.HorizontalAvailabilityCalendar);

    return (
        <RoomAvailabilityCalendarWithFilter
            calendarMode={horizontalMode ? CalendarMode.TwoMonthsWidget : CalendarMode.OneMonthWidget}
        />
    );
};
WidgetCalendar.displayName = "WidgetCalendar";
export default withWidgetContainerAndRefHOC(WidgetCalendar);
