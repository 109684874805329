import styles from "./BookingRatesPanel.scss";
import {SumWithLocale} from "../../../../../components/SumWithLocale/SumWithLocale";
import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import {PromoCodeType} from "../../../../../data/PromoCode";

const BookingPromoCodeInfo = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const {applyResult} = useSelector((state: IAppState) => state.promoCode);
    const {discount, hotelFeatureNames, promoCodeType} = applyResult || {};

    return (
        <div className={styles.total}>
            <div className={styles.summary}>
                <div className={styles.label}>
                    {t(`promoCode.${promoCodeType}`)}
                </div>
                {promoCodeType === PromoCodeType.FreeHotelFeature && (
                    <div className={styles.info}>
                        {hotelFeatureNames.join("; ")}
                    </div>
                )}
            </div>
            {promoCodeType === PromoCodeType.AccommodationDiscount && (
                <div className={styles.sum}>
                    <SumWithLocale sum={discount} />
                </div>
            )}
        </div>
    );
};

BookingPromoCodeInfo.displayName = "BookingPromoCodeInfo";
export default BookingPromoCodeInfo;
