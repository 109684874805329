import {IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IAccommodationsByOccupancyMap, IAccommodationsPricesMap, IAccommodationsRatesMap} from "../AccommodationsMaps";
import {ICurrency} from "../../../types/Currency";
import {Currency} from "../../../common/helpers/Currency";
import {IBookingAccommodation, IBookingPaymentInfo} from "../../../data/Booking";
import {FREE_CHILDREN_COUNT} from "../../../data/HotelInfo";

// TODO Написать тесты

export class AccommodationsPricesHelper {
    static createPricesMap = (roomCategoryAccommodations: IRoomCategoryAccommodation[]): IAccommodationsPricesMap => (
        roomCategoryAccommodations?.reduce((acc, item) => {
            const {roomCategoryId, accommodations} = item;
            return {
                ...acc,
                [roomCategoryId]: accommodations.reduce((acc, item) => {
                    const {occupancyIndex, accommodationPrices} = item;
                    return {
                        ...acc,
                        [occupancyIndex]: accommodationPrices.reduce((acc, item) => {
                            const {rateId} = item;
                            return {
                                ...acc,
                                [rateId]: item
                            };
                        }, {} as IAccommodationsRatesMap)
                    };
                }, {} as IAccommodationsByOccupancyMap<IAccommodationsRatesMap>)
            };
        }, {} as IAccommodationsPricesMap) || {}
    );

    static getRoomCategoryOccupancies = (accommodationsPricesMap: IAccommodationsPricesMap, roomCategoryId: string): number[] => {
        const occupancyMap = accommodationsPricesMap?.[roomCategoryId] || {};
        return Object.keys(occupancyMap).map(Number);
    };

    static getMinRatesPrice = (ratesMap: IAccommodationsRatesMap): ICurrency => {
        const ratePrices = Object.values(ratesMap).map(({price}) => price);
        return ratePrices.length
            ? Currency.getMin(ratePrices)
            : Currency.zero();
    };

    static getBookingPrice = (booking: IBookingAccommodation, pricesMap: IAccommodationsPricesMap): IBookingPaymentInfo => {
        const {rateId, roomCategoryId, adultsCount, childrenCount} = booking;

        const paidChildrenCount = childrenCount ? Math.max(childrenCount - FREE_CHILDREN_COUNT, 0) : 0;
        const occupancyIndex = adultsCount + paidChildrenCount;

        const ratesPrices = pricesMap[roomCategoryId][occupancyIndex] || pricesMap[roomCategoryId][0];
        const {price, prepaymentSum} = ratesPrices[rateId];

        return {
            price: Currency.getFloatValue(price),
            prepayment: Currency.getFloatValue(prepaymentSum)
        };
    }
}
