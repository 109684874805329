import React from "react";
import styles from "../BookingMakeOrder.scss";
import {Link} from "@skbkontur/react-ui";
import cn from "classnames";
import {useTranslation} from "@skbkontur/i18n";
import {bookingLightboxOrderAnalyticsEvents} from "../../../analytics/bookingLightboxOrderAnalyticsEvents";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {PaymentSystem} from "../../../data/Payment";
import {BookingOrderContext} from "../../../providers/BookingOrder/BookingOrderContext";
import {DeviceContext} from "../../../providers/Device/DeviceContext";
import {WidgetClientGlobalStyles} from "../../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../../widget/WidgetStore";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import {RequisitesLightboxContext} from "../../RequisitesLightbox/RequisitesLightboxProvider/RequisitesLightboxContext";
import {BookingPaymentSystemInfo} from "./BookingPaymentSystemInfo";

interface IBookingPaymentPayButtonProps {
    isLoading: boolean;
    paymentSystem: PaymentSystem;
}

export const BookingPaymentPayButton = (props: IBookingPaymentPayButtonProps) => {
    const {isLoading, paymentSystem} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const {isMobileMode} = React.useContext(DeviceContext);
    const {book} = React.useContext(BookingOrderContext);
    const {openRequisitesLightbox} = React.useContext(RequisitesLightboxContext);

    const handleClick = () => book(paymentSystem);

    const handleOpenRequisitesLightbox = () => {
        bookingLightboxOrderAnalyticsEvents.trackOurRequisitesLinkClick();
        openRequisitesLightbox();
    };

    const buttonText = paymentSystem === PaymentSystem.YandexCheckout
        ? t("goToPay")
        : isMobileMode
            ? t("payWith.tBankSbpMobile")
            : t("payWith.tBankSbp");

    const buttonWidgetContainerClass = cn({[WidgetClientGlobalStyles.WidgetBookingFormButtonContainer]: WidgetStore.isWidget()});

    return (
        <div className={styles.paymentButton}>
            <div className={styles.content}>
                <div className={buttonWidgetContainerClass}>
                    <PrimaryButton onClick={handleClick} loading={isLoading} data-tid="PayButton">
                        {buttonText}
                    </PrimaryButton>
                </div>
                <div className={styles.info}>
                    <BookingPaymentSystemInfo paymentSystem={paymentSystem} />
                </div>
            </div>
            <Link className={styles.requisites} use="grayed" onClick={handleOpenRequisitesLightbox}>
                {t("requisites.name")}
            </Link>
        </div>
    );
};

BookingPaymentPayButton.displayName = "BookingPaymentPayButton";
