import {createMapBy} from "../../../common/helpers/arrayUtils";
import {IAccommodationsPricesMap} from "../../Accommodations/AccommodationsMaps";
import {AccommodationsPricesHelper} from "../../Accommodations/helpers/AccommodationsPricesHelper";
import {Currency} from "../../../common/helpers/Currency";
import {IAccommodation, IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";

export interface IAccommodationSortParams {
    roomCategoryId: string;
    occupancyIndex: number;
}

export class BookingLightboxDataHelper {
    static byRoomCategoryOrder = <TItem>(
        roomCategories: IRoomCategory[],
        getRoomCategoryId: (item: TItem) => string
    ) => {
        const roomCategoriesByIdMap = createMapBy(roomCategories, rc => rc.id);
        return (item: TItem) => {
            const roomCategoryId = getRoomCategoryId(item);
            return roomCategoriesByIdMap[roomCategoryId]?.order;
        };
    };

    static getSmallerAccommodations = (
        accommodations: IRoomCategoryAccommodation[],
        requestedPlacesCount: number,
        roomCategories: IRoomCategory[]
    ): IRoomCategoryAccommodation[] => (
        this.getAccommodationsByPlacesFilter(
            accommodations,
            requestedPlacesCount,
            roomCategories,
            (occupancyPlaces, requestedPlaces) => occupancyPlaces < requestedPlaces
        )
    );

    static getEqualOrBiggerAccommodations = (
        accommodations: IRoomCategoryAccommodation[],
        requestedPlacesCount: number,
        roomCategories: IRoomCategory[]
    ): IRoomCategoryAccommodation[] => (
        this.getAccommodationsByPlacesFilter(
            accommodations,
            requestedPlacesCount,
            roomCategories,
            (occupancyPlaces, requestedPlaces) => occupancyPlaces >= requestedPlaces
        )
    );

    private static getAccommodationsByPlacesFilter = (
        accommodations: IRoomCategoryAccommodation[],
        requestedPlacesCount: number,
        roomCategories: IRoomCategory[],
        placesFilter: (occupancyPlaces: number, requestedPlaces: number) => boolean
    ): IRoomCategoryAccommodation[] => {

        const roomCategoryMap = createMapBy(roomCategories, rc => rc.id);
        return accommodations.reduce((acc, item) => {
            const {accommodations, roomCategoryId} = item;
            const roomCategory = roomCategoryMap[roomCategoryId];

            const smallerItemAccommodations = accommodations.filter(({occupancyIndex}) => {
                const placesCount = RoomCategoryHelper.getOccupancyGuestCount(roomCategory, occupancyIndex);
                return placesFilter(placesCount, requestedPlacesCount);
            })

            if (smallerItemAccommodations.length)
                acc.push({...item, accommodations: smallerItemAccommodations});

            return acc;
        }, [] as IRoomCategoryAccommodation[])
    };

    static getSortItemsByPlacesCountASC = (roomCategories: IRoomCategory[]): AccommodationItemsSortType => (
        this.getSortItemsByPlacesCount(roomCategories, 1)
    );

    static getSortItemsByPlacesCountDESC = (roomCategories: IRoomCategory[]): AccommodationItemsSortType => (
        this.getSortItemsByPlacesCount(roomCategories, -1)
    );

    private static getSortItemsByPlacesCount = (
        roomCategories: IRoomCategory[],
        direction: -1 | 1
    ): AccommodationItemsSortType => {
        const roomCategoryMap = createMapBy(roomCategories, rc => rc.id);
        return (
            (roomCategoryId: string) => ({occupancyIndex}: IAccommodation) => (
                direction * RoomCategoryHelper.getOccupancyGuestCount(roomCategoryMap[roomCategoryId], occupancyIndex)
            )
        );
    };

    static getAccommodationsSortParams = (targetPlacesCount: number) => (
        (accommodation: IRoomCategoryAccommodation): IAccommodationSortParams => {
            const {roomCategoryId, accommodations} = accommodation;
            const occupancies = accommodations.map(a => a.occupancyIndex);
            return {
                roomCategoryId,
                occupancyIndex: occupancies.includes(targetPlacesCount)
                    ? targetPlacesCount
                    : occupancies.includes(0)
                        ? 0
                        : Math.max(...occupancies)
            };
        }
    );

    static byPlacesCountASC = <TItem>(
        roomCategories: IRoomCategory[],
        getParams: (item: TItem) => IAccommodationSortParams
    ) => (
        this.byPlacesCount(roomCategories, getParams, 1)
    );

    static byPlacesCountDESC = <TItem>(
        roomCategories: IRoomCategory[],
        getParams: (item: TItem) => IAccommodationSortParams
    ) => (
        this.byPlacesCount(roomCategories, getParams, -1)
    );

    private static byPlacesCount = <TItem>(
        roomCategories: IRoomCategory[],
        getParams: (item: TItem) => IAccommodationSortParams,
        direction: 1 | -1
    ) => {
        const roomCategoriesByIdMap = createMapBy(roomCategories, rc => rc.id);
        return (item: TItem) => {
            const {roomCategoryId, occupancyIndex} = getParams(item);
            const roomCategory = roomCategoriesByIdMap[roomCategoryId];
            const placesCount = RoomCategoryHelper.getOccupancyGuestCount(roomCategory, occupancyIndex);
            return direction * placesCount;
        };
    };

    static byMinRatePrice = <TItem>(
        accommodationsPricesMap: IAccommodationsPricesMap,
        getParams: (item: TItem) => IAccommodationSortParams
    ) => (
        (item: TItem): number => {
            const {roomCategoryId, occupancyIndex} = getParams(item);
            const ratesMap = accommodationsPricesMap?.[roomCategoryId]?.[occupancyIndex];
            const minRatePrice = AccommodationsPricesHelper.getMinRatesPrice(ratesMap);
            return Currency.getFloatValue(minRatePrice);
        }
    );
}

export type AccommodationItemsSortType = (roomCategoryId: string) => (item: IAccommodation) => number