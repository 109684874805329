import React from "react";
import SliderArrows from "./components/SliderArrows";
import SliderDotsList from "./components/SliderDotsList";
import styles from "./Slider.scss";
import SlidesList from "./components/SlidesList";
import {useTouchMove} from "@skbkontur/hotel-hooks/mobile/useTouchMove";
import cn from "classnames";
import {ISliderImage} from "./types/SliderImage";

interface ISliderProps {
    images: ISliderImage[];
    autoPlay?: boolean;
    autoPlayTime?: number;
    emptyImage: string;
    inGallery?: boolean;
    onSlideClick?: (slideIndex: number) => void;
}

const X_OFFSET_TOUCH = 7;
const AUTO_PLAY_TIME = 5000;

const Slider = (props: ISliderProps) => {
    const {
        autoPlay = false,
        autoPlayTime = AUTO_PLAY_TIME,
        images = [],
        emptyImage,
        inGallery,
        onSlideClick
    } = props;

    const [slideIndex, setSlideIndex] = React.useState(0);
    const [isAutoPlayPause, setIsAutoPlayPause] = React.useState(autoPlay);
    const {isTouch, handleTouchMove, handleTouchStart, handleTouchEnd} = useTouchMove({
        offsetTouch: X_OFFSET_TOUCH,
        trigger: "clientX",
        next: () => changeSlide(1),
        back: () => changeSlide(-1),
    });

    const imagesCount = images.length;
    const isManyImages = imagesCount > 1;

    const changeSlide = (direction: number = 1) => {
        const nextSlide = slideIndex + direction;
        const slideNumber = nextSlide < 0 ? imagesCount - 1 : nextSlide % imagesCount;
        setSlideIndex(() => slideNumber);
    };

    const goToSlide = (slideNumber: number) => {
        setSlideIndex(slideNumber % imagesCount);
    };

    React.useEffect(() => {
        if (autoPlay && isAutoPlayPause) {
            const intervalId = setInterval(() => {
                setSlideIndex((prevSlide: number) => (prevSlide + 1 < 0 ? imagesCount - 1 : (prevSlide + 1) % imagesCount));
            }, autoPlayTime);
            return () => clearInterval(intervalId);
        }
    }, [isAutoPlayPause, autoPlay, imagesCount]);

    React.useEffect(() => {
        if (isTouch) {
            setIsAutoPlayPause(false);
        } else if (autoPlay) {
            setIsAutoPlayPause(true);
        }
    }, [isTouch, autoPlay]);

    return (
        <div
            className={cn(styles.slider, {[styles.sliderInGallery]: inGallery && images.length > 1})}
            onClick={() => imagesCount && onSlideClick?.(slideIndex)}
            onMouseEnter={() => setIsAutoPlayPause(false)}
            onMouseLeave={() => setIsAutoPlayPause(true)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {isManyImages && <SliderArrows changeSlide={changeSlide} />}
            <SlidesList
                images={images}
                emptyImage={emptyImage}
                slideNumber={slideIndex}
            />
            {isManyImages && (
                <SliderDotsList
                    slideNumber={slideIndex}
                    images={images}
                    goToSlide={goToSlide}
                />
            )}
        </div>
    );
};

Slider.displayName = "Slider";
export default Slider;
