import {HotelFeaturesMap, IBookingAccommodation} from "../../../../data/Booking";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {getRenderKey} from "@skbkontur/hotel-data/renderKey";

export class BookingAccommodationsHelper {
    static getDefaultAccommodation = (roomCategory: IRoomCategory, occupancyIndex: number, rateId?: string): IBookingAccommodation => {
        const {id} = roomCategory;
        const adultsCount = RoomCategoryHelper.getOccupancyGuestCount(roomCategory, occupancyIndex);
        return {
            roomCategoryId: id,
            adultsCount,
            childrenCount: 0,
            rateId,
            renderKey: getRenderKey(),
            bookingFeatures: {} as HotelFeaturesMap
        };
    };
}
