import React from "react";

export interface IBookingOverbookingContext {
    isOverbooking: () => Promise<boolean>;
    isOverbookingLoading: boolean;
    stopWatchOverbooking: () => void;
}

export const HourlyBookingOverbookingContext = React.createContext({} as IBookingOverbookingContext);

export const BookingOverbookingContext = React.createContext({} as IBookingOverbookingContext);
