import {Locale, SwitchByLocale, useTranslation} from "@skbkontur/i18n";
import {Link} from "@skbkontur/react-ui";
import {Urls} from "../../constants/Urls";
import React from "react";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";

interface IAgreementLinkProps {
    grayed?: boolean;
}

const AgreementLink = ({children, grayed}: React.PropsWithChildren<IAgreementLinkProps>) => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const linkContent = children || t("personalDataAgreement");

    return (
        <SwitchByLocale
            cases={{
                [Locale.Russian]: (
                    <Link use={grayed ? "grayed" : "default"} href={Urls.Agreement} target="_blank">
                        {linkContent}
                    </Link>
                )
            }}
            other={(
                <Link use={grayed ? "grayed" : "default"} href={Urls.EnglishAgreement} target="_blank">
                    {linkContent}
                </Link>
            )}
        />
    );
};

AgreementLink.displayName = "AgreementLink";
export default AgreementLink;
