import React from "react";
import BookingLightboxPanel from "../../Panel/BookingLightboxPanel";
import styles from "./BookingRatesPanel.scss";
import {SumWithLocale} from "../../../../../components/SumWithLocale/SumWithLocale";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {RoomCategorySearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import DateHelper from "../../../../../helpers/DateHelper";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {SelectedAccommodationsHelper} from "../../../../Accommodations/helpers/SelectedAccommodationsHelper";
import {IAppState} from "../../../../../store/AppState";
import {useSelector} from "react-redux";
import {BookingLightboxStep, BookingLightboxStepsContext} from "../../Steps/BookingLightboxStepsContext";
import {Currency} from "../../../../../common/helpers/Currency";
import {bookingLightboxRatesAnalyticsEvents} from "../../../../../analytics/bookingLightboxRatesAnalyticsEvents";
import {DeviceContext} from "../../../../Device/DeviceContext";
import {Gapped} from "@skbkontur/react-ui";
import BookingPromoCodePanel from "./BookingPromoCodePanel";
import BookingPromoCodeInfo from "./BookingPromoCodeInfo";

export const BOOKING_LIGHTBOX_PROMO_CODE_ID = "BookingLightboxPromoCode";

const BookingRatesPanel = () => {
    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const {step, goToStep} = React.useContext(BookingLightboxStepsContext);
    const {selectedAccommodationsMap, accommodationsPricesMap} = React.useContext(AccommodationsContext);
    const {isDesktopMode} = React.useContext(DeviceContext);

    const {hotelFeatures} = useSelector((state: IAppState) => state.hotelFeatures);
    const {isAvailable, applyResult} = useSelector((state: IAppState) => state.promoCode);

    const selectedBookings = SelectedAccommodationsHelper.toSelectedAccommodationBookings(selectedAccommodationsMap);

    const {freeChildrenAge: age} = useSelector((state: IAppState) => state.hotelInfo.info);
    const {params: {fromDate, toDate}} = React.useContext(RoomCategorySearchParamsContext);
    const nightsCount = DateHelper.getDiffByDays(fromDate, toDate);
    const allBookingsCount = selectedBookings.length;

    const bookingsWithRates = selectedBookings.filter(b => !!b.accommodation.rateId);
    const bookingsWithoutRatesCount = allBookingsCount - bookingsWithRates.length;

    const hotelFeaturesSum = SelectedAccommodationsHelper.getHotelFeaturesPrice(selectedBookings, hotelFeatures);
    const totalSum = SelectedAccommodationsHelper.getTotalPrice(bookingsWithRates, accommodationsPricesMap);
    const totalSumWithPromoCodeDiscount = Currency.subtract(totalSum, applyResult?.discount);
    const finalSum = Currency.add(totalSumWithPromoCodeDiscount, hotelFeaturesSum);
    const isPanelShowed = !Currency.isZero(totalSum);

    const adultsCount = selectedBookings.reduce((acc, {accommodation: {adultsCount}}) => acc + adultsCount, 0);
    const childrenCount = selectedBookings.reduce((acc, {accommodation: {childrenCount}}) => acc + childrenCount, 0);

    const bookingAccommodations = selectedBookings.map(b => b.accommodation);
    const isRatesFilled = SelectedAccommodationsHelper.isRatesFilled(bookingAccommodations);

    React.useEffect(() => {
        if (isRatesFilled && step === BookingLightboxStep.SelectRates) {
            bookingLightboxRatesAnalyticsEvents.trackGoToPayment();
            goToStep(BookingLightboxStep.FinishOrder);
        }
    }, [isRatesFilled]);

    const showInfo = isDesktopMode || isRatesFilled;

    return (
        <>
            {isAvailable && isRatesFilled && (
                <div id={BOOKING_LIGHTBOX_PROMO_CODE_ID}>
                    <BookingPromoCodePanel />
                </div>
            )}
            {applyResult?.promoCodeId && isRatesFilled && <BookingPromoCodeInfo />}
            <BookingLightboxPanel isShown={isPanelShowed} isStatic={isRatesFilled}>
                <Gapped gap={30} vertical>
                    <div className={styles.total}>
                        <div className={styles.summary}>
                            <div className={styles.label}>
                                {t("total.final", {nightsCount, roomsCount: allBookingsCount})}
                            </div>
                            {showInfo && (
                                <div className={styles.info}>
                                    {tcn("fromDateToDate", {fromDate, toDate})}<br />
                                    {childrenCount
                                        ? t("total.guestsAdultsAndChildren", {adultsCount, childrenCount, age})
                                        : t("total.guestsAdults", {adultsCount})}
                                </div>
                            )}
                        </div>
                        {!isRatesFilled && (
                            <div className={styles.warning}>
                                {t("validations.selectRate", {roomsCount: bookingsWithoutRatesCount})}
                            </div>
                        )}
                        <div className={styles.sum}>
                            <SumWithLocale sum={finalSum} />
                        </div>
                    </div>
                </Gapped>
            </BookingLightboxPanel>
        </>
    );
};
BookingRatesPanel.displayName = "BookingRatesPanel";
export default BookingRatesPanel;
