import React from "react";
import {IRoomCategoryAccommodation} from "../../../../../data/Accommodation";
import BookingAccommodationsItem from "./BookingAccommodationsItem";
import {Gapped} from "@skbkontur/react-ui";
import FlippedElement from "../../../../../common/components/Flipper/Element/FlippedElement";
import {useFlipperMountStagger} from "../../../../../common/components/Flipper/useFlipperMountStagger";
import {BookingLightboxFlipperStaggers} from "../../Flipper/BookingLightboxFlipperStaggers";
import styles from "./BookingAccommodationsList.scss";
import {FlipperHelper} from "../../../../../common/components/Flipper/FlipperHelper";
import {useShouldFlipStepsChange} from "../../Flipper/useShouldFlipStepsChange";

interface IBookingAccommodationsListProps {
    accommodation: IRoomCategoryAccommodation;
}

const BookingAccommodationsList = (props: IBookingAccommodationsListProps) => {
    const {accommodation} = props;
    const {roomCategoryId, accommodations} = accommodation;

    const stagger = useFlipperMountStagger(BookingLightboxFlipperStaggers.Occupancy);
    const shouldFlip = useShouldFlipStepsChange();

    return (
        <Gapped gap={20} vertical>
            {accommodations.map(item => (
                <FlippedElement
                    key={item.occupancyIndex}
                    flipId={item.occupancyIndex}
                    shouldFlip={shouldFlip}
                    onAppear={FlipperHelper.onAppear(styles.fadeIn, 300)}
                    withContext
                    stagger={stagger}
                >
                    <BookingAccommodationsItem roomCategoryId={roomCategoryId} accommodation={item}/>
                </FlippedElement>
            ))}
        </Gapped>
    );
};
BookingAccommodationsList.displayName = "BookingAccommodationsList";
export default React.memo(BookingAccommodationsList);
