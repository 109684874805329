import React from "react";
import {Modal} from "@skbkontur/react-ui";
import {useTranslation, CommonTranslationNamespaces} from "@skbkontur/i18n";
import {useLightboxResize} from "../../hooks/useLightboxResize";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {ErrorLightboxHelper} from "./ErrorLightboxHelper";

export interface IErrorLightboxProps {
    title: React.ReactNode;
    message?: string;
    onClose: () => void;
    children?: React.ReactNode;
}

const ErrorLightbox = (props: IErrorLightboxProps) => {
    const {title, children, onClose, message} = props;
    const {tcn} = useTranslation(CommonTranslationNamespaces.Common);
    const width = useLightboxResize(ErrorLightboxHelper.getWidth);

    return (
        <Modal disableFocusLock width={width} onClose={onClose} ignoreBackgroundClick>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>
                <div>
                    {message}
                    {children}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton use="default" onClick={onClose}>
                    {tcn("buttons.tryAgain")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};
ErrorLightbox.displayName = "ErrorLightbox";
export default ErrorLightbox;
