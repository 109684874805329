import React from "react";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../store/AppState";
import styles from "./BookingLightboxOrder.scss";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";

const BookingLightboxOrderCheckInOut = () => {
    const {checkInTime, checkOutTime} = useSelector((state: IAppState) => state.hotelInfo.info);
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    return (checkInTime && checkOutTime && (
        <div className={styles.checkingTime}>
            {t("standardCheckinCheckout", {
                reactParams: {
                    checkinTimeSpan: <span className={styles.bold}>{checkInTime}</span>,
                    checkoutTimeSpan: <span className={styles.bold}>{checkOutTime}</span>
                }
            })}
            <br />
            {t("ifNeedAnotherTimeNote")}
        </div>
    ));
};
BookingLightboxOrderCheckInOut.displayName = "BookingLightboxOrderCheckInOut";
export default BookingLightboxOrderCheckInOut;
