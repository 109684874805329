// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o6kwjz{align-items:center;display:flex;padding-right:24px;position:relative}.nUXBZ9{position:absolute;right:0;top:0}.WkjlMF{font-weight:700}.SzKmrK{padding-right:17px;position:relative}.MR2LsC{position:absolute;right:0;top:0}", "",{"version":3,"sources":["webpack://./src/components/PlacementParameters/PlacementParameters.scss"],"names":[],"mappings":"AAAA,QAII,kBAAA,CADA,YAAA,CADA,kBAAA,CADA,iBAGA,CAGJ,QACI,iBAAA,CAEA,OAAA,CADA,KACA,CAGJ,QACI,eAAA,CAGJ,QAEI,kBAAA,CADA,iBACA,CAGJ,QACI,iBAAA,CACA,OAAA,CACA,KAAA","sourcesContent":[".bedRow {\r\n    position: relative;\r\n    padding-right: 24px;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.bedImage {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n}\r\n\r\n.boldText {\r\n    font-weight: 700;\r\n}\r\n\r\n.iconRow {\r\n    position: relative;\r\n    padding-right: 17px;\r\n}\r\n\r\n.icon {\r\n    position: absolute;\r\n    right: 0;\r\n    top: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bedRow": "o6kwjz",
	"bedImage": "nUXBZ9",
	"boldText": "WkjlMF",
	"iconRow": "SzKmrK",
	"icon": "MR2LsC"
};
export default ___CSS_LOADER_EXPORT___;
