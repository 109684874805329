import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import BookingLightboxContainer from "../../BookingLightboxContainer";
import styles from "./BookingExceedCapacityResult.scss";

const BookingExceedCapacityResult = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    return (
        <BookingLightboxContainer>
            <div className={styles.root}>
                <div className={styles.title}>
                    {t("accommodations.warnings.noExist")}
                </div>
                {t("accommodations.warnings.noExistDescription")}
            </div>
        </BookingLightboxContainer>
    );
};
BookingExceedCapacityResult.displayName = "BookingExceedCapacityResult";
export default BookingExceedCapacityResult;
