import React from "react";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {CancellationPenaltyType} from "../../data/Rate";
import {ICurrency} from "../../types/Currency";
import {useCurrencySymbol} from "../Currency/useCurrencySymbol";
import {useSumWithLocale} from "../SumWithLocale/useSumWithLocale";
import {Currency} from "../../common/helpers/Currency";

interface IReteConditionsCancellationPenaltyProps {
    cancellationPenalty: number;
    cancellationPenaltySum: ICurrency;
    cancellationPenaltyType: CancellationPenaltyType;
}

export const ReteConditionsCancellationPenalty = (props: IReteConditionsCancellationPenaltyProps) => {
    const {cancellationPenalty, cancellationPenaltySum, cancellationPenaltyType} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingConfirmation);

    const currencySymbol = useCurrencySymbol();
    const sumWithLocal = useSumWithLocale(cancellationPenaltySum);
    const absoluteCurrencyValue = `${sumWithLocal} ${currencySymbol}`;

    switch (true) {
        case Currency.isZero(cancellationPenaltySum):
            return (
                <div data-tid="cancellationPenaltyNone">
                    {t("cancellationPenaltyNone", {raw: true})}
                </div>
            );
        case cancellationPenaltyType === CancellationPenaltyType.PercentOfFirstDayCost:
            return (
                <div data-tid="cancellationPenaltyFirstDayCost">
                    {t("cancellationPenaltyFirstDayCost", {
                        cancellationPenaltyValue: cancellationPenalty,
                        measure: "%",
                        absoluteCurrencyValue,
                        raw: true
                    })}
                </div>
            );
        case cancellationPenaltyType === CancellationPenaltyType.PercentOfFirstHourCost:
            return (
                <div data-tid="cancellationPenaltyFirstHourlyCost">
                    {t("cancellationPenaltyFirstHourlyCost", {
                        cancellationPenaltyValue: cancellationPenalty,
                        measure: "%",
                        absoluteCurrencyValue,
                        raw: true,
                    })}
                </div>
            );
        case cancellationPenaltyType === CancellationPenaltyType.PercentOfReservationCost:
            return (
                <div data-tid="cancellationPenaltyReservationCost">
                    {t("cancellationPenaltyReservationCost", {
                        cancellationPenaltyValue: cancellationPenalty,
                        measure: "%",
                        absoluteCurrencyValue,
                        raw: true,
                    })}
                </div>
            );
        default:
            return (
                <div data-tid="cancellationPenaltyAbsolute">
                    {t("cancellationPenaltyAbsolute", {
                        cancellationPenaltyValue: absoluteCurrencyValue,
                        raw: true,
                    })}
                </div>
            );
    }
};

ReteConditionsCancellationPenalty.displayName = "ReteConditionsCancellationPenalty";
