import React from "react";
import {PaymentResult, IPaymentData, IPayment} from "../../data/Payment";

export interface IPaymentContext {
    createPayment: (
        paymentData: IPaymentData,
        onFinishPayment: (result: PaymentResult) => void
    ) => Promise<IPayment>;

    isPaymentProcess: boolean;
    paymentUrl: string;
}

export const PaymentContext = React.createContext({} as IPaymentContext);
