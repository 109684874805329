import {Currency, ICurrency} from "../../common/helpers/Currency";
import React from "react";
import {KonturI18NContext} from "@skbkontur/i18n";

export const useSumWithLocale = (sum: ICurrency|number): string => {
    const {locale} = React.useContext(KonturI18NContext);
    sum = sum || 0;
    const floatSum = typeof sum === "number" ? sum : Currency.getFloatValue(sum);
    return floatSum.toLocaleString(locale);
};
