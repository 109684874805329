import React from "react";
import {Gapped} from "@skbkontur/react-ui";
import styles from "./BookingPaymentSum.scss";
import {SumWithLocale} from "../SumWithLocale/SumWithLocale";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {ITranslatableProps, useTranslation} from "@skbkontur/i18n";
import {ICurrency} from "../../types/Currency";

interface IBookingPaymentSumProps extends ITranslatableProps {
    sumValue: ICurrency;
}

export const BookingPaymentSum = (props: IBookingPaymentSumProps) => {
    const {sumValue} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingLightbox);

    return (
        <div className={styles.prepayment}>
            <Gapped gap={20}>
                <span className={styles.prepaymentLabel}>{t("prepayment")}</span>
                <span className={styles.prepaymentPrice} data-tid="PaymentSum">
                    <SumWithLocale sum={sumValue} />
                </span>
            </Gapped>
        </div>
    );
};

BookingPaymentSum.displayName = "BookingPaymentSum";
