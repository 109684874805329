import React from "react";
import {ValidationContainer} from "@skbkontur/react-ui-validations";
import styles from "./BookingSearchForm.scss";
import {IRoomCategorySearchParams} from "../../data/SearchParams";
import cn from "classnames";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import DatesPeriodControl from "../DatesPeriodControl/DatesPeriodControl";
import GuestsSelectControl from "../GuestsSelectControl/GuestsSelectControl";
import BookingSearchFormButton from "./BookingSearchFormButton";
import {DeviceContext, DeviceMode} from "../../providers/Device/DeviceContext";
import {mainSearchAnalyticsEvents} from "../../analytics/mainSearchAnalyticsEvents";
import {bookingLightboxSearchPanelAnalyticsEvents} from "../../analytics/bookingLightboxSearchPanelAnalyticsEvents";
import DateHelper from "../../helpers/DateHelper";
import BookingLightboxContainer from "../../providers/Booking/BookingLightbox/BookingLightboxContainer";
import {useMount} from "@skbkontur/hotel-hooks/react";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import {useEffectWithoutInitCall} from "../../hooks/useEffectWithoutInitCall";
import {isEqual} from "lodash";

export interface IBookingSearchFormProps {
    params: IRoomCategorySearchParams;
    onSearch: (params?: IRoomCategorySearchParams) => void;
    onChange?: (params?: IRoomCategorySearchParams) => void;
    inline?: boolean;
    autoFocus?: boolean;
    searchOnMount?: boolean;
    alwaysActiveButton?: boolean;
    withoutBorder?: boolean;
    noContainerPadding?: boolean;
    onFrontPage?: boolean;
}

export interface IBookingSearchFormState extends IRoomCategorySearchParams {
    needSearch: boolean;
}

const BookingSearchForm = (props: IBookingSearchFormProps) => {
    const {
        params,
        onSearch,
        onChange,
        searchOnMount,
        autoFocus,
        inline,
        alwaysActiveButton,
        withoutBorder,
        noContainerPadding,
        onFrontPage
    } = props;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);
    const {deviceMode} = React.useContext(DeviceContext);

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const [state, setState] = React.useState<IBookingSearchFormState>({
        ...params,
        needSearch: true
    });
    const {needSearch, ...stateParams} = state;
    const {fromDate, toDate, adultsCount, kidsCount, roomCategoryId} = stateParams;

    const containerRef = React.useRef<ValidationContainer>();
    const datePeriodRef = React.useRef<DatesPeriodControl>();

    useEffectWithoutInitCall(() => {
        setState(state => {
            const {needSearch, ...stateParams} = state;
            if (isEqual(params, stateParams))
                return state;
            return {...state, ...params};
        });
    }, [params]);

    const partialFormChange = (params: Partial<IRoomCategorySearchParams>) => {
        setState(state => {
            const newParams = {
                ...state,
                ...params,
                needSearch: true
            };
            if (onChange) {
                const {needSearch, ...searchParams} = newParams;
                onChange(searchParams);
            }
            return newParams;
        });
    };

    const handleSubmit = async () => {
        const isValid = await containerRef.current.validate();
        if (isValid) {
            const newState = {
                ...state,
                roomCategoryId: roomCategoryId || null,
                needSearch: false
            };
            setState(newState);
            const {needSearch, ...searchParams} = newState;
            onSearch(searchParams);
        }
    };

    useMount(() => {
        if (searchOnMount) {
            // TODO Переписать, когда будет binder. Сейчас так, чтобы не подсвечивать красным при открытии ЛБ брони.
            const {toDatePicker, fromDatePicker} = datePeriodRef.current.validate(t);
            const isValidationOk = !toDatePicker && !fromDatePicker;
            isValidationOk && handleSubmit();
        }
    });

    const onButtonSearch = () => {
        // alwaysActiveButton indicates search form in hotel page
        alwaysActiveButton
            ? mainSearchAnalyticsEvents.trackSearchFormRunSearch()
            : bookingLightboxSearchPanelAnalyticsEvents.trackRestartSearch();
        handleSubmit();
    };

    const isButtonUsed = !alwaysActiveButton && !needSearch;

    const handleCheckinChange = (fromDate: string) => {
        const nightsCount = DateHelper.getDiffByDays(fromDate, toDate);
        bookingLightboxSearchPanelAnalyticsEvents.trackDatePeriodChange(nightsCount);
        partialFormChange({fromDate});
    };

    const handleCheckoutChange = (toDate: string) => {
        const nightsCount = DateHelper.getDiffByDays(fromDate, toDate);
        bookingLightboxSearchPanelAnalyticsEvents.trackDatePeriodChange(nightsCount);
        partialFormChange({toDate});
    };

    const handleAdultsChange = (adultsCount: number) => {
        bookingLightboxSearchPanelAnalyticsEvents.trackGuestsChange(adultsCount, kidsCount);
        partialFormChange({adultsCount});
    };

    const handleKidsChange = (kidsCount: number) => {
        bookingLightboxSearchPanelAnalyticsEvents.trackGuestsChange(adultsCount, kidsCount);
        partialFormChange({kidsCount});
    };

    const containerClasses = cn({
        [styles.containerWithoutPadding]: noContainerPadding || onFrontPage
    });

    const controlClasses = cn(styles.control, {
        [styles.inline]: inline,
        [styles.withoutBorder]: withoutBorder,
        [styles.controlOnFrontPage]: onFrontPage
    });

    return (
        <BookingLightboxContainer className={containerClasses}>
            <div className={controlClasses}>
                <ValidationContainer ref={containerRef}>
                    <div className={styles.controlsWrapper}>
                        <div className={styles.dates}>
                            <DatesPeriodControl
                                fromField={{
                                    value: fromDate,
                                    placeholder: tcn("checkin"),
                                    onChange: handleCheckinChange,
                                }}
                                toField={{
                                    value: toDate,
                                    placeholder: tcn("checkout"),
                                    onChange: handleCheckoutChange
                                }}
                                isAutoFocus={autoFocus}
                                offsetInMinutes={offsetInMinutes}
                                ref={ref => datePeriodRef.current = ref}
                                hideDash={deviceMode === DeviceMode.Mobile}
                            />
                        </div>
                        <div className={styles.guestsSelect}>
                            <GuestsSelectControl
                                adults={{
                                    count: adultsCount,
                                    onChange: handleAdultsChange
                                }}
                                kids={{
                                    count: kidsCount,
                                    onChange: handleKidsChange
                                }}
                                onApply={() => datePeriodRef.current?.focusFirstEmptyPicker()}
                            />
                        </div>
                        <BookingSearchFormButton isButtonUsed={isButtonUsed} onClick={onButtonSearch}/>
                    </div>
                </ValidationContainer>
            </div>
        </BookingLightboxContainer>
    );
};
BookingSearchForm.displayName = "BookingSearchForm";
export default BookingSearchForm;
