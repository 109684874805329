import React from "react";
import styles from "./CalendarDay.scss";
import cn from "classnames";

interface ICalendarDayProps {
    day?: number;
    description?: string;
    dayOfWeek?: React.ReactNode;
    disabled: boolean;
    isSingleMonth: boolean;
}

const CalendarDay = (props: ICalendarDayProps) => {
    const {day, description, dayOfWeek, disabled, isSingleMonth} = props;

    return (
        <div className={cn(styles.day, {[styles.dayFilled]: !!description, [styles.dayDisabled]: disabled})}>
            <div className={styles.dayNumber}>
                {day}
            </div>
            {day && (
                <div className={cn(styles.dayDescription, {[styles.dayDescriptionSmall]: isSingleMonth})}>
                    {description || dayOfWeek}
                </div>
            )}
        </div>
    );
};
CalendarDay.displayName = "CalendarDay";
export default CalendarDay;
