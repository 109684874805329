import React from "react";
import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";
import {IRate} from "../data/Rate";

export const useHourlyRate = (rateId: string): IRate => {
    const {hourlyRates} = useSelector((state: IAppState) => state.hourlyRates);
    return React.useMemo(() => (
        hourlyRates.find(rc => rc.id === rateId) || ({} as IRate)
    ), [hourlyRates, rateId]);
};
