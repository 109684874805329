import {IBookingAccommodationVariant} from "../../../data/Booking";
import {orderBy, uniq} from "lodash";
import {IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IAccommodationsByOccupancyMap, IAccommodationsVariantsMap} from "../AccommodationsMaps";
import {FREE_CHILDREN_COUNT} from "../../../data/HotelInfo";
import {createMapBy} from "../../../common/helpers/arrayUtils";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";

export class AccommodationsVariantsHelper {
    static getAdultsCountVariants = (occupancyVariants: IBookingAccommodationVariant[]): number[] => (
        uniq(occupancyVariants.map(ov => ov.adultsCount))
    );

    static getChildrenCountVariants = (occupancyVariants: IBookingAccommodationVariant[], adultsCount: number): number[] => (
        uniq(occupancyVariants.filter(ov => ov.adultsCount === adultsCount).map(ov => ov.childrenCount))
    );

    static createVariantsMap = (
        roomCategoryAccommodations: IRoomCategoryAccommodation[],
        roomCategories: IRoomCategory[]
    ): IAccommodationsVariantsMap => {
        const roomCategoryMap = createMapBy(roomCategories, rc => rc.id);
        return (
            roomCategoryAccommodations?.reduce((acc, item) => {
                const {roomCategoryId, accommodations} = item;
                const roomCategory = roomCategoryMap[roomCategoryId];
                const occupancies = accommodations.map(a => a.occupancyIndex);
                if (!roomCategory) {
                    captureSentryError(
                        {error: "Unexpected room category in BM search", roomCategoryId},
                        SentryErrorType.Inconsistency
                    );
                    return acc;
                }
                return {
                    ...acc,
                    [roomCategoryId]: this.getVariantsMap(roomCategory, occupancies)
                };
            }, {} as IAccommodationsVariantsMap) || {}
        );
    };

    private static getVariantsMap = (
        roomCategory: IRoomCategory,
        occupancies: number[]
    ): IAccommodationsByOccupancyMap<IBookingAccommodationVariant[]> => {
        const {roomCategoryType} = roomCategory;

        if (RoomCategoryHelper.isHostel(roomCategoryType))
            return {0: [{adultsCount: 1, childrenCount: 0}]};

        const orderedOccupancies = this.getOrderedOccupancies(occupancies);
        const variantsChecker = new AccommodationsVariantsChecker();

        return orderedOccupancies.reduce((result, occupancy) => {
            const placesCount = RoomCategoryHelper.getOccupancyGuestCount(roomCategory, occupancy);
            const placesVariants = this.getPlacesVariants(placesCount);

            const unusedVariants = placesVariants.filter(variantsChecker.isNotUsedVariant);
            unusedVariants.forEach(variantsChecker.addUsedVariant);

            return {
                ...result,
                [occupancy]: unusedVariants
            };
        }, {} as IAccommodationsByOccupancyMap<IBookingAccommodationVariant[]>);
    };

    private static getOrderedOccupancies = (occupancies: number[]): number[] => {
        const ascOccupancies = orderBy<number>(occupancies, x => x, ["asc"]);
        const [minOccupancy, ...otherOccupancies] = ascOccupancies;
        return minOccupancy === 0 ? [...otherOccupancies, 0] : ascOccupancies;
    };

    private static getPlacesVariants = (placesCount: number): IBookingAccommodationVariant[] => {
        const results = [];
        for (let adultsCount = 1; adultsCount <= placesCount; ++adultsCount) {
            const maxChildrenCount = placesCount - adultsCount + FREE_CHILDREN_COUNT;
            for (let childrenCount = 0; childrenCount <= maxChildrenCount; ++childrenCount) {
                results.push({adultsCount, childrenCount});
            }
        }
        return results;
    };
}

class AccommodationsVariantsChecker {
    private usedVariants = new Set<string>();

    isNotUsedVariant = ({adultsCount, childrenCount}: IBookingAccommodationVariant) => (
        !this.usedVariants.has(`${adultsCount}_${childrenCount}`)
    );

    addUsedVariant = ({adultsCount, childrenCount}: IBookingAccommodationVariant) => {
        this.usedVariants.add(`${adultsCount}_${childrenCount}`);
    };
}
