import React from "react";
import styles from "../Slider.scss";
import {Img} from "../../image";

interface ISlideProps {
    url: string;
}

const Slide = (props: ISlideProps) => {
    const {url} = props;

    return (
        <div className={styles.slide}>
            <Img src={url} />
        </div>
    );
};

Slide.displayName = "Slide";
export default Slide;
