import React from "react";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import {IHourlyObjectsItemAvailableTime} from "../HourlyObjectsListAvailableTime";
import {AvailabilitiesHourlyType, TimestampType} from "../../../data/Availability";
import {Compare} from "../../../helpers/CompareHelper";
import {first, last} from "lodash";

export interface IFilterHourly {
    fromItems: IHourlyObjectsItemAvailableTime[];
    toItems: IHourlyObjectsItemAvailableTime[];
}

export const ONE_HOUR_IN_MS = 60 * 60 * 1000;

const fillTimeArray = (availabilitiesHourly: AvailabilitiesHourlyType): number[] => {
    const sortedTimestamps = Object.keys(availabilitiesHourly)
        .map((el: string) => Number(el))
        .sort(Compare.asc);

    if (!sortedTimestamps.length || sortedTimestamps.length === 1) {
        return sortedTimestamps;
    }

    const firstTimestamps = first(sortedTimestamps);
    const lastTimestamps = last(sortedTimestamps);
    const result: TimestampType[] = [];

    for (let i = firstTimestamps; i <= lastTimestamps; i += ONE_HOUR_IN_MS) {
        result.push(i);
    }

    return result;
};

export const useHourlyAvailabilities = () => {
    const {availabilitiesHourly, availabilitiesTo} = useSelector((state: IAppState) => state.availabilitiesHourly);

    const [data, setData] = React.useState<IFilterHourly>({
        fromItems: [],
        toItems: [],
    });

    React.useEffect(() => {
        if (!availabilitiesHourly) {
            return;
        }

        const fromItems: IHourlyObjectsItemAvailableTime[] = [];
        const toItems: IHourlyObjectsItemAvailableTime[] = [];
        const hourTimestamps = fillTimeArray(availabilitiesHourly);

        hourTimestamps.forEach((timestamp: number) => {
            const price = availabilitiesHourly[timestamp]?.price;
            const isUnavailable = !price;
            const nextTimestamp = timestamp + ONE_HOUR_IN_MS;

            fromItems.push({timestamp, price, isUnavailable});
            toItems.push({timestamp: nextTimestamp, price, isUnavailable});
        });

        setData({fromItems, toItems});
    }, [availabilitiesHourly]);

    React.useEffect(() => {
        if(!availabilitiesTo) {
            return;
        }

        const toItems: IHourlyObjectsItemAvailableTime[] = []

        const sortedTimestamps = Object.keys(availabilitiesTo)
        .map((el: string) => Number(el))
        .sort(Compare.asc);

        sortedTimestamps.forEach((timestamp: number) => {
            const price = availabilitiesTo[timestamp]?.price;
            const isUnavailable = !price;

            toItems.push({timestamp, price, isUnavailable});
        });

        setData((prev: IFilterHourly) => ({...prev, toItems}))
    }, [availabilitiesTo])

    return data;
};
