import React from "react";
import PaymentProvider from "./Payment/PaymentProvider";
import {BookingProvider} from "./Booking/BookingProvider";
import {RequisitesLightboxProvider} from "../components/RequisitesLightbox/RequisitesLightboxProvider/RequisitesLightboxProvider";
import {useDispatch} from "react-redux";
import {getRoomCategories} from "../store/roomCategories/roomCategoriesActionCreators";
import {getRates} from "../store/rates/ratesActionCreators";
import {getHotelInfo} from "../store/hotelInfo/hotelInfoActionCreators";
import {getHotelFeatures} from "../store/hotelFeatures/hotelFeaturesActionCreators";
import {getHourlyObjects} from "../store/hourlyObjects/hourlyObjectsActionCreators";
import {getHourlyRates} from "../store/hourlyRates/hourlyRatesActionCreators";
import {getHourlyFeatures} from "../store/hourlyFeatures/hourlyFeaturesActionCreators";
import {HourlyBookingProvider} from "./HourlyBooking/HourlyBookingProvider";
import {BookingSuccessProvider} from "./BookingSuccess/BookingSuccessProvider";
import {useMount} from "@skbkontur/hotel-hooks/react";

const AllBookingProviders = ({children}: React.PropsWithChildren<object>) => {
    const dispatch = useDispatch();

    useMount(() => {
        dispatch(getRoomCategories());
        dispatch(getRates());
        dispatch(getHotelInfo());
        dispatch(getHotelFeatures());

        dispatch(getHourlyObjects());
        dispatch(getHourlyRates());
        dispatch(getHourlyFeatures());
    });

    return (
        <RequisitesLightboxProvider>
            <BookingSuccessProvider>
                <PaymentProvider>
                    <BookingProvider>
                        <HourlyBookingProvider>
                            {children}
                        </HourlyBookingProvider>
                    </BookingProvider>
                </PaymentProvider>
            </BookingSuccessProvider>
        </RequisitesLightboxProvider>
    );
};
AllBookingProviders.displayName = "AllBookingProviders";
export default AllBookingProviders;
