import React from "react";
import {getTN, tType} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {IRate} from "../rate";
import {BedType, IRoomCategory, IRoomStructure, RoomCategoryType, RoomType} from "./RoomCategory";
import {withRenderKey} from "../renderKey";
import {getNotDeletedItems} from "../utils";

const BEDS_CAPACITY: Record<BedType, number> = {
    [BedType.Single]: 1,
    [BedType.Double]: 2,
    [BedType.DoubleKing]: 2,
    [BedType.DoubleSplit]: 2,
    [BedType.Bunk]: 2
};

export class RoomCategoryHelper {
    static isHostelCategory = (category: IRoomCategory): boolean => (
        !!category && this.isHostel(category.roomCategoryType)
    );

    static isHostel = (type: RoomCategoryType): boolean => (
        type === RoomCategoryType.Hostel
    );

    static getGuestsCount = (category: IRoomCategory): number => (
        this.isHostelCategory(category) ? 1 : category.placesMin
    );

    static getOccupancyGuestCount = (roomCategory: IRoomCategory, occupancyIndex: number): number => (
        this.isHostel(roomCategory.roomCategoryType)
            ? 1
            : occupancyIndex === 0
                ? roomCategory.placesMin
                : occupancyIndex
    );

    static getOccupancyName = (
        roomCategoryType: RoomCategoryType,
        occupancy = 0,
        tWithRoomCategories: tType
    ): React.ReactNode => {
        const tRates = getTN(tWithRoomCategories, TranslationNamespaces.Rates);
        return occupancy !== 0
            ? tRates("occupancyName", {occupancyNumber: occupancy})
            : this.getWholeNumberName(roomCategoryType, tWithRoomCategories);
    }

    static getWholeNumberName = (
        roomCategoryType: RoomCategoryType,
        tWithRoomCategories: tType
    ) => {
        const isHostel = this.isHostel(roomCategoryType);
        const tRoomCategories = getTN(tWithRoomCategories, TranslationNamespaces.RoomCategories);
        return isHostel
            ? tRoomCategories("roomCategoryType.bed")
            : tRoomCategories("roomCategoryType.wholeNumber");
    };

    static getDefaultRoomCategory = (roomCategory?: Partial<IRoomCategory>): IRoomCategory => ({
        id: null,
        name: "",
        description: "",
        imageSources: [],
        roomCategoryType: null,
        area: null,
        hasKitchen: false,
        bathroomType: null,
        placesMin: 1,
        roomsCount: 1,
        rooms: [withRenderKey({
            placesCount: 1,
            beds: [withRenderKey({
                type: null
            })],
            type: RoomType.LivingOrBedroom
        })],
        ...roomCategory
    });

    static hasRoomCategoryRate = (id: string, rates: IRate[]): boolean => {
        const notDeletedRates = getNotDeletedItems(rates || []);
        return notDeletedRates.some(rate =>
            rate.roomCategoriesOccupancies.some(occupancy => occupancy.roomCategoryId === id)
        );
    };

    static roomWithRenderKeys = (room: IRoomStructure): IRoomStructure => withRenderKey({
        ...room,
        beds: room.beds?.map(withRenderKey)
    });

    static calculateRoomBedsCapacity = (bedTypes: BedType[]): number => (
        bedTypes?.reduce((sum, type) => sum + BEDS_CAPACITY[type], 0)
    );

    static getTranslationKeyByType = (type: RoomCategoryType) => (
        this.isHostel(type) ? "Hostel" : "WholeRoom"
    );
}
