import * as Sentry from "@sentry/react";
import {getStageENV, isDev, isFuncTest, isStageTest} from "@skbkontur/hotel-process-env";
import {MatchPath, RouteConfig, RouterHistory} from "@sentry/react/types/reactrouter";

interface IReactRouterOptions {
    history: RouterHistory;
    routes?: RouteConfig[];
    matchPath?: MatchPath;
}

export enum SentryTag {
    Type = "type",
    User = "user",
    Organization = "organization"
}

export enum SentryErrorType {
    ErrorBoundary = "errorBoundary",
    SwitchCase = "switchCase",
    Memoize = "memoize",
    IsLoading = "isLoading",
    Plugin = "plugin",
    FormUpdater = "formUpdater",
    VirtualCache = "virtualCache",
    Store = "store",
    Fetcher = "fetcher",
    GlobalError = "globalError",
    Path = "path",
    Component = "component",
    Inconsistency = "inconsistency",
    SupportCenter = "supportCenter",
    Toolbox = "toolbox",
    I18 = "I18",
    JsonParse = "jsonParse",
    Dayjs = "dayjs",
    Redux = "redux"
}

export const captureSentryError = <TInfoValue>(info: TInfoValue, type: SentryErrorType) => {
    if (isDev()) {
        /* eslint-disable-next-line no-console */
        console.error(info);
    } else {
        Sentry.captureException(new Error(JSON.stringify(info)), {
            tags: {
                [SentryTag.Type]: type
            }
        });
    }
};

interface IInitSentryOptions {
    options: Sentry.BrowserOptions;
    useProfiling?: boolean;
    reactRouterOptions: IReactRouterOptions;
}

export const initSentry = ({useProfiling = true, reactRouterOptions, options}: IInitSentryOptions) => {
    if (!isDev()) {
        Sentry.init({
            environment: getStageENV(),
            maxBreadcrumbs: 20,
            beforeBreadcrumb: (breadcrumb, hint) => {
                if (breadcrumb.category === "ui.click") {
                    breadcrumb.message += ` ${hint.event.target?.lastChild?.data}`;
                }

                if (breadcrumb.category === "fetch" || breadcrumb.category === "xhr") return null;
                if (breadcrumb.category === "console" && breadcrumb.level !== "error") return null;

                return breadcrumb;
            },
            integrations: !isFuncTest() && useProfiling ? [
                Sentry.reactRouterV5BrowserTracingIntegration(reactRouterOptions),
                Sentry.browserProfilingIntegration()
            ] : [],
            tracesSampleRate: isStageTest() ? 1 : 0.2,
            ...options
        });
    }
};