import React from "react";
import {FlipperHelper} from "../../../../common/components/Flipper/FlipperHelper";
import styles from "./HourlyBookingLightboxFlipper.scss";
import FlippedElement from "../../../../common/components/Flipper/Element/FlippedElement";
import {HourlyBookingLightboxFlipperHelper} from "./HourlyBookingLightboxFlipperHelper";
import {HourlyBookingStaggersFlipper} from "./HourlyBookingStaggersFlipper";

interface IHourlyBookingAccommodationFlipperProps {
    renderKey: string;
    hourlyRateId: string;
}

const HourlyBookingAccommodationFlipper = (props: React.PropsWithChildren<IHourlyBookingAccommodationFlipperProps>) => {
    const {renderKey, hourlyRateId, children} = props;

    return (
        <FlippedElement
            flipId={hourlyRateId}
            shouldFlip={HourlyBookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}
            onAppear={FlipperHelper.onAppear(styles.fadeIn400, 400)}
            stagger={HourlyBookingStaggersFlipper.Rate}
            withDiv
            withContext
        >
            {children}
        </FlippedElement>
    );
};
HourlyBookingAccommodationFlipper.displayName = "HourlyBookingAccommodationFlipper";
export default HourlyBookingAccommodationFlipper;
