import React from "react";
import CounterText from "../../../../../components/Counter/CounterText";
import {IAccommodation} from "../../../../../data/Accommodation";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {BookingAccommodationsHelper} from "../BookingAccommodationsHelper";
import BookingAccommodationsOccupancy from "./ByOccupancy/BookingAccommodationsOccupancy";
import styles from "./BookingAccommodationsList.scss";
import {useRoomCategory} from "../../../../../hooks/useRoomCategory";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import BookingLightboxSumWithLabel from "../../SumWithLabel/BookingLightboxSumWithLabel";
import {AccommodationsPricesHelper} from "../../../../Accommodations/helpers/AccommodationsPricesHelper";
import {Currency} from "../../../../../common/helpers/Currency";
import cn from "classnames";
import {DeviceContext} from "../../../../Device/DeviceContext";
import {bookingLightboxOccupancyAnalyticsEvents} from "../../../../../analytics/bookingLightboxOccupancyAnalyticsEvents";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";

interface IBookingAccommodationsItemProps {
    roomCategoryId: string;
    accommodation: IAccommodation;
}

const BookingAccommodationsItem = (props: IBookingAccommodationsItemProps) => {
    const {roomCategoryId, accommodation} = props;
    const {occupancyIndex} = accommodation;

    const {isMobileMode} = React.useContext(DeviceContext);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const roomCategory = useRoomCategory(roomCategoryId);
    const {roomCategoryType} = roomCategory;

    const {
        accommodationsAvailabilityMap,
        selectedAccommodationsMap,
        accommodationsPricesMap,
        addAccommodation,
        removeAccommodation
    } = React.useContext(AccommodationsContext);

    const {availableCount} = accommodationsAvailabilityMap[roomCategoryId];
    const accommodationsByOccupancy = selectedAccommodationsMap?.[roomCategoryId]?.[occupancyIndex] || [];
    const accommodationsByOccupancyCount = accommodationsByOccupancy.length;

    const ratesMap = accommodationsPricesMap?.[roomCategoryId]?.[occupancyIndex] || {};
    const minRatePrice = AccommodationsPricesHelper.getMinRatesPrice(ratesMap);
    const minTotalSum = accommodationsByOccupancyCount
        ? Currency.multiply(minRatePrice, accommodationsByOccupancyCount)
        : minRatePrice;

    const getDefaultAccommodation = React.useCallback(() => {
        const rates = Object.keys(ratesMap);
        const rateId = rates.length === 1 ? rates[0] : null;
        return BookingAccommodationsHelper.getDefaultAccommodation(roomCategory, occupancyIndex, rateId);
    }, [roomCategory, occupancyIndex, ratesMap]);

    const emptyAccommodationItems = React.useMemo(() => [getDefaultAccommodation()], [getDefaultAccommodation]);

    const onChangeAccommodationsCount = (newCount: number) => {
        bookingLightboxOccupancyAnalyticsEvents.trackOccupancyCountChange(newCount);
        if (newCount > accommodationsByOccupancyCount) {
            addAccommodation({
                roomCategoryId,
                occupancyIndex,
                accommodation: getDefaultAccommodation()
            });
        }
        if (newCount < accommodationsByOccupancyCount) {
            removeAccommodation({
                roomCategoryId,
                occupancyIndex,
                index: accommodationsByOccupancyCount - 1
            });
        }
    };

    const isListActive = accommodationsByOccupancyCount > 0;
    const accommodationsList = isListActive ? accommodationsByOccupancy : emptyAccommodationItems;

    const maxValueHint = RoomCategoryHelper.isHostel(roomCategoryType)
        ? t("validations.noMorePlaces")
        : t("validations.noMoreRooms");

    const counter = (
        <div className={styles.counter}>
            <CounterText
                maxValue={availableCount + accommodationsByOccupancyCount}
                maxValueHint={maxValueHint}
                value={accommodationsByOccupancyCount}
                onChange={onChangeAccommodationsCount}
            />
        </div>
    );

    const sumWithLabel = (
        <div className={cn(styles.sumWithLabel, {[styles.disabled]: !isListActive})}>
            <BookingLightboxSumWithLabel
                sum={minTotalSum}
                isHostel={RoomCategoryHelper.isHostel(roomCategoryType)}
                accommodationsCount={accommodationsList.length}
                shortOnMobile
            />
        </div>
    );

    const accommodations = (
        <BookingAccommodationsOccupancy
            roomCategoryId={roomCategoryId}
            occupancyIndex={occupancyIndex}
            accommodations={accommodationsList}
            disabled={!isListActive}
        />
    );

    return isMobileMode ? (
        <div className={cn(styles.byOccupancy, styles.rowItems)}>
            {sumWithLabel}
            <div className={styles.columnRightItems}>
                {counter}
                {accommodations}
            </div>
        </div>
    ) : (
        <div className={cn(styles.byOccupancy, styles.columnRightItems)}>
            {counter}
            {sumWithLabel}
            {accommodations}
        </div>
    );
};
BookingAccommodationsItem.displayName = "BookingAccommodationsItem";
export default React.memo(BookingAccommodationsItem);
