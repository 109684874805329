import React from "react";

export enum DeviceSize {
    Mobile = 320,
    MobileLarge = 414,
    TableSize = 768,
    DesktopMiniSize = 1024,
    DesktopSize = 1280,
    DesktopLargeSize = 1920
}

export enum DeviceMode {
    Mobile = "mobile",
    MobileLarge = "mobileLarge",
    Tablet = "tablet",
    DesktopMini = "desktopMini",
    Desktop = "desktop",
    DesktopLarge = "desktopLarge"
}

export interface IDeviceContext {
    deviceMode: DeviceMode;
    isDesktopMode: boolean;
    isMobileMode: boolean;
}

export const DeviceContext = React.createContext({} as IDeviceContext);
