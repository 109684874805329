import {useTranslation} from "@skbkontur/i18n";
import React from "react";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IRate} from "../../data/Rate";
import {ICurrency} from "../../types/Currency";
import {ReteConditionsCancellationPenalty} from "./ReteConditionsCancellationPenalty";
import {RateConditionsFreeCancellation} from "./RateConditionsFreeCancellation";
import {RateConditionsPrepayment} from "./RateConditionsPrepayment";

interface IRateConditionsProps {
    cancellationPenaltySum: ICurrency;
    prepaymentSum: ICurrency;
    checkin: string;
    rate: IRate;
}

const RateConditions = (props: IRateConditionsProps) => {
    const {cancellationPenaltySum, prepaymentSum, checkin, rate} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingConfirmation);

    const {
        cancellationPenalty,
        cancellationPenaltyType,
        prepayment,
        prepaymentType,
        isNoneRefundable,
        freeCancellationHoursCount
    } = rate;

    return (
        <div>
            <RateConditionsPrepayment
                prepayment={prepayment}
                prepaymentType={prepaymentType}
                prepaymentSum={prepaymentSum}
            />
            {!isNoneRefundable && (
                <RateConditionsFreeCancellation
                    freeCancellationHoursCount={freeCancellationHoursCount}
                    checkin={checkin}
                />
            )}
            {!isNoneRefundable && (
                <ReteConditionsCancellationPenalty
                    cancellationPenalty={cancellationPenalty}
                    cancellationPenaltyType={cancellationPenaltyType}
                    cancellationPenaltySum={cancellationPenaltySum}
                />
            )}
            {isNoneRefundable && (
                <div data-tid="prepaymentNonRefundable">
                    {t("prepaymentNonRefundable", {raw: true})}
                </div>
            )}
        </div>
    );
};
RateConditions.displayName = "RateConditions";
export default RateConditions;
