import React from "react";
import {Input} from "@skbkontur/react-ui";
import OrderFormRow from "../Row/OrderFormRow";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {BookingOrderContext} from "../../BookingOrder/BookingOrderContext";
import {createValidationInfo} from "../../../forms/ValidationInfo";
import {IBookingOrderState} from "../../../data/BookingOrder";
import {ValidatorsFormatHelper} from "@skbkontur/hotel-validators/helpers/ValidatorsFormatHelper";
import {FormFieldName, useTrackFormFieldFill} from "../../../hooks/useTrackFormFieldFill";

const OrderFormPhone = () => {
    const {t, tcn} = useTranslation(TranslationNamespaces.BookingLightbox);

    const {
        setForm,
        form: {customer},
    } = React.useContext(BookingOrderContext);
    const {phone} = customer;

    const getValidation = () => {
        if (!phone) return createValidationInfo(tcn("enterPhone"));
        if (!ValidatorsFormatHelper.isPhoneOrEmpty(phone)) return createValidationInfo(tcn("validations.digitsPhone"));
    };

    const handleChange = (phone: string) => {
        setForm((value: IBookingOrderState) => ({
            ...value,
            customer: {...value.customer, phone},
        }));
    };

    const onBlur = useTrackFormFieldFill(FormFieldName.Email);

    return (
        <OrderFormRow caption={t("form.Other.phone")} validationInfo={getValidation()} required>
            <Input
                width={200}
                size="medium"
                onValueChange={handleChange}
                onBlur={onBlur} />
        </OrderFormRow>
    );
};
OrderFormPhone.displayName = "OrderFormPhone";
export default OrderFormPhone;
