import React from "react";
import {BedType, IBedStructure, IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {SvgIcon} from "@skbkontur/hotel-components/svgIcon";
import {BED_ICONS} from "./RoomCategoryCharacteristicsIcons";
import styles from "../RoomCategoryCharacteristics.scss";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";

interface IRoomCategoryCharacteristicsHintProps {
    category: IRoomCategory;
    beds: IBedStructure[];
    modifyImageUrl?: (url: string) => string;
}

const RoomCategoryCharacteristicsHint = (props: IRoomCategoryCharacteristicsHintProps) => {
    const {category: {placesMin}, beds, modifyImageUrl} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const bedCountsMap: Record<BedType, number> = beds?.reduce((bedsMap, {type}) => ({
        ...bedsMap,
        [type]: bedsMap[type] ? bedsMap[type] + 1 : 1
    }), {} as Record<BedType, number>);

    return (
        <div>
            <div className={styles.hintTitle}>
                {t("placesCount", {count: placesMin})}
            </div>
            <div>
                {bedCountsMap && Object.keys(bedCountsMap).map(bedType => (
                    <div key={bedType} className={styles.hintBedRow}>
                        <SvgIcon key={bedType} type={BED_ICONS[bedType]} modifyImageUrl={modifyImageUrl} />
                        <span>&nbsp;—&nbsp;</span>
                        {bedCountsMap[bedType] > 1
                            ? t(`bedTypesWithCount.${bedType}`, {count: bedCountsMap[bedType]})
                            : t(`bedTypes.${bedType}`)}
                    </div>
                ))}
            </div>
        </div>
    );
};
RoomCategoryCharacteristicsHint.displayName = "RoomCategoryCharacteristicsHint";
export default RoomCategoryCharacteristicsHint;