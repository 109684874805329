import React from "react";
import ReactDOM from "react-dom";
import styles from "../../WidgetApp.scss";
import {WidgetSettingsRoots} from "../../settings/WidgetSettingsRoots";
import {IWidget, IWidgetRefresh} from "../../settings/WidgetSettingsTypes";

interface IWidgetContainerAndRefProps {
    hotelId: string;
    widget: IWidget;
}

export const withWidgetContainerAndRefHOC = <TProps extends IWidgetContainerAndRefProps>(Component: React.ComponentType<TProps>) => (
    React.memo(React.forwardRef((props: TProps, ref: React.ForwardedRef<IWidgetRefresh>) => {
        const {hotelId, widget: {id, type, appearance}} = props;

        // eslint-disable-next-line react/hook-use-state
        const [, setRefreshCount] = React.useState<number>(1);

        React.useImperativeHandle(ref, () => ({
            refresh() {
                setRefreshCount(refreshCount => refreshCount + 1);
            }
        }));

        // eslint-disable-next-line no-console
        console.log(`Widget render ${id}`);

        const container = WidgetSettingsRoots.createWidgetRoot(hotelId, type, appearance);

        if (!container) return null;

        return (
            ReactDOM.createPortal((
                <div className={styles.container}>
                    <Component {...props}/>
                </div>
            ), container)
        );
    }))
);
