import React from "react";
import {IHourlyObjectSearchParams, IPlaceSearchParams, IRoomCategorySearchParams, KnownSearchParams} from "../../data/SearchParams";

/** This interface is not extendable, if you wish to add some params, please add them to TParams interface */
export interface ISearchParamsContext<TParams extends KnownSearchParams> {
    params: TParams;
    setParams: (action: (params: TParams) => TParams) => void;
}

export const RoomCategorySearchParamsContext = React.createContext(
    {} as ISearchParamsContext<IRoomCategorySearchParams>
);
export const HourlyObjectsSearchParamsContext = React.createContext(
    {} as ISearchParamsContext<IHourlyObjectSearchParams>
);
export const PlaceSearchParamsContext = React.createContext({} as ISearchParamsContext<IPlaceSearchParams>);
