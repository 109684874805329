import React from "react";
import WidgetStore from "../../widget/WidgetStore";
import IconBed from "../../images/IconBed.svg";
import styles from "./PlacementParameters.scss";
import cn from "classnames";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";

interface IPlacementParametersProps {
    roomCategory?: IRoomCategory;
    capacity?: number;
    isBold?: boolean;
}

const PlacementParameters = (props: IPlacementParametersProps) => {
    const {roomCategory, capacity, isBold} = props;
    const {roomCategoryType, placesMin, placesMax} = roomCategory || {};

    if (RoomCategoryHelper.isHostel(roomCategoryType)) {
        return (
            <div className={styles.bedRow}>
                <span className={cn({[styles.boldText]: isBold})}>
                    {placesMax === placesMin ? placesMin : `${placesMin}−${placesMax}`}
                </span>
                <img
                    className={styles.bedImage}
                    width={20}
                    height={20}
                    src={WidgetStore.getUrlWithBase(IconBed)}
                    alt="iconBed"
                />
            </div>
        );
    }

    return (
        <div className={styles.iconRow}>
            <span className={cn({[styles.boldText]: isBold})}>{placesMin || capacity}</span>
            <div className={styles.icon}>
                <People1Icon16Light />
            </div>
        </div>
    );
};
PlacementParameters.displayName = "PlacementParameters";
export default PlacementParameters;
