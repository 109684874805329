import React from "react";
import HotelBookingSearchForm from "../../../components/BookingSearchForm/HotelBookingSearchForm";
import {IWidget, WidgetType} from "../../settings/WidgetSettingsTypes";
import {WidgetValidation} from "../../validation/WidgetValidation";
import {withWidgetContainerAndRefHOC} from "../Widget/withWidgetContainerAndRefHOC";

interface IWidgetBookingControlProps {
    widget: IWidget;
}

const WidgetBookingControl = (props: IWidgetBookingControlProps) => {
    const {widget: {type}} = props;

    const inlineMode = WidgetValidation.areEqualEnums<WidgetType>(type, WidgetType.HorizontalBookingForm);

    return (
        <HotelBookingSearchForm inline={inlineMode} noContainerPadding/>
    );
};
WidgetBookingControl.displayName = "WidgetBookingControl";
export default withWidgetContainerAndRefHOC(WidgetBookingControl);
