import React from "react";
import styles from "./TranslationLoader.scss";
import cn from "classnames";

interface ITranslationLoaderProps {
    className?: string;
}

const TranslationLoader = (props: ITranslationLoaderProps) => {
    const {className} = props;
    return <span className={cn(styles.root, className)} />;
};
TranslationLoader.displayName = "TranslationLoader";
export default TranslationLoader;
