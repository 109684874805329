import React from "react";
import FlippedElement from "../../../../../common/components/Flipper/Element/FlippedElement";
import {HourlyBookingLightboxFlipperHelper} from "../../Flipper/HourlyBookingLightboxFlipperHelper";
import styles from "./HourlyBookingRateItem.scss";

interface IHourlyBookingRateItemNameProps {
    renderKey: string;
    name: string;
}

const HourlyBookingRateItemName = (props: IHourlyBookingRateItemNameProps) => {
    const {name, renderKey} = props;

    return (
        <FlippedElement
            flipId="HourlyRates"
            shouldFlip={HourlyBookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}
            withInverseParent
            inline
        >
            <span className={styles.name}>{name}</span>
        </FlippedElement>
    );
};
HourlyBookingRateItemName.displayName = "HourlyBookingRateItemName";
export default HourlyBookingRateItemName;
