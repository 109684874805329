import React from "react";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import styles from "./RoomCategoryCharacteristics.scss";
import {useTranslation} from "@skbkontur/i18n";
import {SvgIcon, SvgIconType} from "@skbkontur/hotel-components/svgIcon";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";

interface IRoomCategoryCharacteristicsAmenitiesProps {
    category: IRoomCategory;
    modifyImageUrl?: (url: string) => string;
}

const RoomCategoryCharacteristicsAmenities = (props: IRoomCategoryCharacteristicsAmenitiesProps) => {
    const {category, modifyImageUrl} = props;
    const {hasKitchen, bathroomType} = category;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const isHasBathroomType = !!bathroomType;
    const isHasKitchen = !!hasKitchen;

    if (!(isHasBathroomType || isHasKitchen)) {
        return null;
    }

    return (
        <div className={styles.amenities}>
            {isHasBathroomType && (
                <div className={styles.iconBlock}>
                    <SvgIcon type={SvgIconType.Shower} modifyImageUrl={modifyImageUrl} />
                    <span>{t(`bathroomTypesTags.${bathroomType}`)}</span>
                </div>
            )}
            {isHasKitchen && (
                <div className={styles.iconBlock}>
                    <SvgIcon type={SvgIconType.Kitchen} modifyImageUrl={modifyImageUrl} />
                    <span>{t("hasKitchen")}</span>
                </div>
            )}
        </div>
    );
};
RoomCategoryCharacteristicsAmenities.displayName = "RoomCategoryCharacteristicsAmenities";
export default RoomCategoryCharacteristicsAmenities;