import React from "react";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import {SelectedAccommodationsHelper} from "../../../Accommodations/helpers/SelectedAccommodationsHelper";
import BookingRatesAccommodation from "./BookingRatesAccommodation";
import BookingLightboxFlipperBooking from "../Flipper/BookingLightboxFlipperBooking";
import {sortBy} from "lodash";
import {ISelectedAccommodationBooking} from "../../../Accommodations/AccommodationsMaps";
import {IAppState} from "../../../../store/AppState";
import {useDispatch, useSelector} from "react-redux";
import {BookingLightboxDataHelper, IAccommodationSortParams} from "../BookingLightboxDataHelper";
import BookingRatesPanel from "./Panel/BookingRatesPanel";
import {clearPromoCodeInfo, getIsAnyPromoCodeAvailable} from "../../../../store/promoCode/promoCodeActionCreators";
import BookingLightboxContainer from "../BookingLightboxContainer";
import {useMount} from "@skbkontur/hotel-hooks/react";

const BookingRates = () => {
    const dispatch = useDispatch();

    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {selectedAccommodationsMap, accommodationsPricesMap} = React.useContext(AccommodationsContext);
    const accommodationBookings = SelectedAccommodationsHelper.toSelectedAccommodationBookings(selectedAccommodationsMap);

    const getRoomCategoryId = ({params: {roomCategoryId}}: ISelectedAccommodationBooking) => roomCategoryId;
    const sortByRoomCategory = BookingLightboxDataHelper.byRoomCategoryOrder(roomCategories, getRoomCategoryId);

    const getSortParams = ({params}: ISelectedAccommodationBooking): IAccommodationSortParams => params;
    const sortByMinRatePrice = BookingLightboxDataHelper.byMinRatePrice(accommodationsPricesMap, getSortParams);

    const sortedAccommodations = sortBy<ISelectedAccommodationBooking>(accommodationBookings, sortByRoomCategory, sortByMinRatePrice);
    const sortedAccommodationsCount = sortedAccommodations.length;

    useMount(() => {
        dispatch(getIsAnyPromoCodeAvailable());
        return () => {
            dispatch(clearPromoCodeInfo());
        };
    });

    React.useEffect(() => {
        dispatch(clearPromoCodeInfo());
    }, [selectedAccommodationsMap]);

    return (
        <BookingLightboxContainer>
            {sortedAccommodations.map((ab, index) => (
                <BookingLightboxFlipperBooking
                    key={ab.accommodation.renderKey}
                    renderKey={ab.accommodation.renderKey}
                    isLast={index === sortedAccommodationsCount - 1}
                >
                    <BookingRatesAccommodation
                        listIndex={index}
                        accommodationBooking={ab}
                        single={sortedAccommodationsCount === 1}
                    />
                </BookingLightboxFlipperBooking>
            ))}
            <BookingRatesPanel/>
        </BookingLightboxContainer>
    );
};
BookingRates.displayName = "BookingRates";
export default React.memo(BookingRates);
