import React from "react";
import {useRate} from "../../../../../hooks/useRate";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import { createMapBy } from "../../../../../common/helpers/arrayUtils";

interface IBookingRateItemIncludesProps {
    rateId: string;
}

const BookingRateItemIncludes = (props: IBookingRateItemIncludesProps) => {
    const {rateId} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const hotelFeatures = useSelector((state: IAppState) => state.hotelFeatures.hotelFeatures);
    const hotelFeaturesMap = createMapBy(hotelFeatures, hf => hf.id);

    const {rateFeatures} = useRate(rateId);
    const rateFeaturesNames = rateFeatures.map(rf => hotelFeaturesMap[rf]?.name);

    const includesItems = [
        t("rates.includesLiving", {stringFormat: true}),
        ...(rateFeaturesNames || [])
    ].join("; ");

    return (
        <div>
            {t("rates.includes", {
                reactParams: {
                    0: <>{includesItems}</>
                }
            })}
        </div>
    );
};
BookingRateItemIncludes.displayName = "BookingRateItemIncludes";
export default BookingRateItemIncludes;
